import type { CurriculumCourse } from '@/types/CurriculumCourse';
import type { CurriculumModule } from '@/types/CurriculumModule';
import type { CurriculumTerm } from '@/types/CurriculumTerm';

export function getCourses(terms: CurriculumTerm[] = [], onlyWithProgress = false) {
  const courses = [
    terms.flatMap((term) => mapModules(term.curriculumModules, onlyWithProgress)),
    terms
      .flatMap((term) => term.curriculumStructureElements)
      .flatMap((structureElement) =>
        mapModules(structureElement.curriculumModules, onlyWithProgress)
      ),
  ].flat();

  return courses;
}

/**
 * Include course in the mapping if course has progress or if onlyWithProgress is false.
 */
function includeCourse(course: CurriculumCourse, onlyWithProgress: boolean) {
  return onlyWithProgress
    ? !!course.courseProgress?.examResults?.length || !!course.courseProgress?.recognition
    : true;
}

/**
 * Maps modules to courses with or without progress depending on the parameter.
 */
function mapModules(curriculumModules: CurriculumModule[], onlyWithProgress: boolean) {
  return curriculumModules
    .flatMap((curriculumModule) => curriculumModule.curriculumCourses)
    .filter((course) => includeCourse(course, onlyWithProgress));
}
