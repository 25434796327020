<template>
  <div class="w-full h-full">
    <table class="w-full mt-5 table-fixed">
      <tr>
        <th class="uppercase text-left text-blue-600 text-base w-5/12">Ausgewählte Kurse</th>
        <th class="uppercase text-left text-blue-600 text-base w-2/12">Note</th>
        <th class="uppercase text-left text-blue-600 text-base w-5/12">Link</th>
      </tr>
      <tr class="w-full h-5" colspan="3" />
      <recognition-table-row
        v-for="selectedCourseRecognitionOptions of selectedCoursesRecognitionOptions"
        :key="selectedCourseRecognitionOptions.curriculumCourse.id"
        :selected-course-recognition-options="selectedCourseRecognitionOptions"
      ></recognition-table-row>
    </table>
  </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex';
import RecognitionTableRow from './RecognitionTableRow.vue';

export default {
  name: 'RecognitionTable',
  components: {
    RecognitionTableRow,
  },
  computed: {
    ...mapState('overview', ['selectedCoursesRecognition']),
    ...mapGetters('studyProgresses', ['findManyCurriculumCoursesByIds']),

    selectedCoursesRecognitionOptions() {
      const selectedCurriculumCourses = this.findManyCurriculumCoursesByIds(
        Object.keys(this.selectedCoursesRecognition)
      );

      return selectedCurriculumCourses.map((curriculumCourse) => ({
        ...this.selectedCoursesRecognition[curriculumCourse.id],
        curriculumCourse,
      }));
    },
  },
};
</script>
