<template>
  <tbody v-show="active" data-test="grades-overview__module" class="odd:bg-blue-100">
    <tr class="h-20 text-center">
      <td class="px-1 pl-3 text-left">
        <p data-test="grade-overview-module__name" class="font-bold truncate">
          {{ curriculumModule.moduleTitle }}
        </p>
        <p data-test="grade-overview-module__version" class="text-xs text-gray-700">
          Version: {{ curriculumModule.moduleDescriptionVersionCode }}
        </p>
      </td>
      <td data-test="grade-overview-module__code" class="px-1 font-bold">
        {{ curriculumModule.curriculumModuleCode }}
      </td>
      <td data-test="grade-overview-module__ects" class="px-1 font-bold">
        {{ curriculumModule.ects }}
      </td>
      <td data-test="grade-overview-module__status" class="px-1">
        <span class="flex justify-center">
          <module-status
            v-if="curriculumModule.moduleProgress"
            :status="curriculumModule.moduleProgress.status"
          />
        </span>
      </td>
      <td data-test="grade-overview-module__last-valid-graduation" class="px-1 font-bold">
        <module-grade
          v-if="curriculumModule.moduleProgress && curriculumModule.moduleProgress.grade"
          :status="curriculumModule.moduleProgress.status"
          :is-graded="curriculumModule.moduleProgress.grade.isGraded"
          :grade="curriculumModule.moduleProgress.grade.grade"
        />
      </td>
      <td colspan="3"></td>
      <td
        v-if="!isRecognitionModeActive"
        data-test="grade-overview-module__action"
        class="text-right px-1 pr-4"
      >
        <course-and-module-actions
          :curriculum-module="curriculumModule"
        ></course-and-module-actions>
      </td>
    </tr>
    <grades-table-curriculum-course
      v-for="curriculumCourse of curriculumModule.curriculumCourses"
      :key="curriculumCourse.id"
      :curriculum-module="curriculumModule"
      :curriculum-course="curriculumCourse"
    />
  </tbody>
</template>

<script>
import ModuleGrade from '@/components/common/grade/ModuleGrade.vue';
import GradesTableCurriculumCourse from '@/components/overview/GradesTableCurriculumCourse.vue';
import ModuleStatus from '@/components/common/module-status/ModuleStatus.vue';
import CourseAndModuleActions from './CourseAndModuleActions.vue';
import { mapState } from 'vuex';

export default {
  name: 'GradesTableCurriculumModule',
  components: {
    ModuleStatus,
    GradesTableCurriculumCourse,
    ModuleGrade,
    CourseAndModuleActions,
  },
  props: {
    active: {
      type: Boolean,
      required: true,
    },
    curriculumModule: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState('overview', ['isRecognitionModeActive']),
  },
};
</script>
