// eslint-disable-next-line no-unused-vars
import { StudyProgressAPI } from '@/services/study-progress-api';

export default {
  async deleteRecognition({ dispatch }, recognitionId) {
    try {
      await StudyProgressAPI.deleteRecognition(recognitionId);

      await dispatch(`studyProgresses/fetchDataForSelectedStudyProgress`, {}, { root: true });
      await dispatch(`app/navigateToOverview`, {}, { root: true });
    } catch (error) {
      console.error(error);
      dispatch('app/setError', error, { root: true });
    }
  },
};
