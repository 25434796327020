import { loadAndUpdateI18n } from '@epos/orchestrator/i18n';
import en from '@/i18n/en.json';
import { type App } from 'vue';
import { createI18n, type I18n } from 'vue-i18n';

const i18n = createI18n({ legacy: false, fallbackLocale: ['en'], messages: { en } });

loadAndUpdateI18n(i18n, 'nitro');

export const useTranslations = (app: App) => {
  app.use(i18n);
  return i18n as I18n;
};

export const t = i18n.global.t;
export const d = i18n.global.d;
export const locale = i18n.global.locale;
export const getLocaleMessage = i18n.global.getLocaleMessage;
