<template>
  <div
    v-if="!studyPlanLoaded || (studyPlanLoaded && !getCurriculumTerms.length)"
    class="w-full flex justify-center mt-12"
  >
    <empty-search-result @reset-search="resetSearch" />
  </div>
  <div v-else>
    <grades-table class="grades-table mt-10 overflow-scroll">
      <grades-table-head :column-config="columnConfig" />
      <grades-table-curriculum-term
        v-for="curriculumTerm of getCurriculumTerms"
        :key="`${curriculumTerm.id}${key}`"
        :curriculum-term="curriculumTerm"
      />
    </grades-table>
  </div>
</template>
<script>
import { mapMutations, mapGetters, mapState } from 'vuex';
import GradesTable from '@/components/common/GradesTable.vue';
import GradesTableHead from '@/components/common/GradesTableHead.vue';
import GradesTableCurriculumTerm from '@/components/overview/GradesTableCurriculumTerm.vue';
import { v4 as uuidv4 } from 'uuid';
import EmptySearchResult from '@/components/common/EmptySearchResult.vue';

export default {
  name: 'GradesOverview',
  components: {
    EmptySearchResult,
    GradesTable,
    GradesTableHead,
    GradesTableCurriculumTerm,
  },
  data: () => ({
    key: uuidv4(),
  }),
  computed: {
    ...mapGetters({
      studyPlanLoaded: `studyProgresses/studyPlanLoaded`,
      searchTerm: `overview/getSearchTerm`,
      getCurriculumTerms: `studyProgresses/getCurriculumTerms`,
    }),
    ...mapState('overview', ['isRecognitionModeActive']),
    columnConfig() {
      const columnConfig = [
        { label: 'Name', class: '' },
        { label: 'Kürzel/Id', class: 'w-36' },
        { label: 'ECTS-Punkte', class: 'w-28' },
        { label: 'Status', class: 'w-16' },
        { label: 'Note', class: 'w-16' },
        { label: 'Versuch', class: 'w-20' },
        { label: 'Herkunft', class: 'w-24' },
        { label: 'Link', class: 'w-11 text-center' },
      ];

      if (!this.isRecognitionModeActive) {
        columnConfig.push({ label: 'Aktion', class: 'w-16 text-right' });
      }

      return columnConfig;
    },
  },
  watch: {
    studyPlanLoaded() {
      // Whenever the getter `studyPlanLoaded` changes, generate a new uuid
      // to be set as the :key, which will force an absolute re-render,
      // avoiding rendering/virtual DOM diffing inconsistencies
      this.key = uuidv4();
    },
    searchTerm() {
      // Whenever the getter `searchTerm` changes, generate a new uuid
      // to be set as the :key, which will force an absolute re-render,
      // avoiding rendering/virtual DOM diffing inconsistencies
      this.key = uuidv4();
    },
  },
  methods: {
    ...mapMutations({
      resetSearch: `overview/resetSearchTerm`,
    }),
  },
};
</script>
