<template>
  <recognition-split-view id="epos-grades">
    <template #splitViewContent>
      <n-applet-wrapper id="epos-grades-main" style="min-width: 44rem">
        <n-applet-page>
          <template #header>
            <n-view-navigation :links="links" :breadcrumb-links="[]">
              <template #headline>
                <span class="flex flex-col">
                  <span class="text-xs font-normal"> Kundenprofil </span>
                  {{ fullProfileName }}
                </span>
              </template>
            </n-view-navigation>
          </template>
          <template #content>
            <grades-loader v-if="loading">{{ loading }}</grades-loader>
            <template v-else>
              <error-banner />
              <router-view :key="$route.fullPath" />
              <snackbar />
            </template>
          </template>
        </n-applet-page>
      </n-applet-wrapper>
    </template>
  </recognition-split-view>
</template>

<script>
import { makeCustomerServiceTabs } from '@epos/orchestrator/deprecated';
import { NAppletWrapper, NViewNavigation, NAppletPage } from '@careerpartner/nitro';
import { mapActions, mapGetters } from 'vuex';
import GradesLoader from '@/components/common/Loader.vue';
import ErrorBanner from '@/components/common/ErrorBanner.vue';
import Snackbar from '@/components/common/Snackbar.vue';
import RecognitionSplitView from '@/components/overview/recognition/RecognitionSplitView.vue';
import { useRoute } from 'vue-router';

export default {
  name: 'GradesApplet',
  components: {
    ErrorBanner,
    GradesLoader,
    NAppletWrapper,
    NViewNavigation,
    Snackbar,
    RecognitionSplitView,
    NAppletPage,
  },
  data() {
    return {
      loading: null,
      routeWatcher: null,
    };
  },
  computed: {
    ...mapGetters({
      getProfile: `app/getProfile`,
      getDamFolderUrl: 'app/getDamFolderUrl',
    }),
    links() {
      const links = makeCustomerServiceTabs(this.$route.params.id, this.getDamFolderUrl);
      if (links !== undefined)
        return links.map((link) => ({
          label: link.label,
          to: link.label === 'Dokumente' ? undefined : link.path,
          href: link.label === 'Dokumente' ? link.path : undefined,
        }));
      return [];
    },
    fullProfileName() {
      if (Object.keys(this.getProfile).length) {
        const { givenName, familyName, birthName } = this.getProfile.person;
        const writtenBirthName = birthName ? ` (${birthName})` : '';
        return `${givenName} ${familyName}${writtenBirthName}`;
      } else {
        return '...';
      }
    },
  },
  async beforeMount() {
    this.resetState();
    const route = useRoute();
    this.loading = 'App is loading...';

    if (route.params.id) {
      await this.loadProfile(route.params.id);
      this.loading = null;
    } else {
      this.routeWatcher = this.$watch(
        () => route.params.id,
        async (newVal) => {
          if (newVal) {
            await this.loadProfile(newVal);
            this.loading = null;
          }
        }
      );
    }
  },
  async unmounted() {
    // remove watcher
    if (this.routeWatcher) this.routeWatcher();
  },
  mounted() {
    /**
     * Since n-menu is rendered in a table the app always throws multiple errors when a menu is opened.
     * This code removes the error message from the DOM. (the iframe overlay). Since there is no guarantee on the amount of errors thrown,
     * this code is executed in a while loop until there are no more errors.
     */
    if (import.meta.env && import.meta.env.DEV) {
      window.addEventListener('error', (e) => {
        if (e.message === 'ResizeObserver loop completed with undelivered notifications.') {
          let resizeObserverErr = document.getElementById('webpack-dev-server-client-overlay');
          while (resizeObserverErr) {
            if (resizeObserverErr) {
              resizeObserverErr.remove();
            }
            resizeObserverErr = document.getElementById('webpack-dev-server-client-overlay');
            const resizeObserverErrDiv = document.getElementById(
              'webpack-dev-server-client-overlay-div'
            );
            if (resizeObserverErrDiv) {
              resizeObserverErrDiv.remove();
            }
          }
        }
      });
    }
  },
  methods: {
    ...mapActions({
      loadProfile: `app/loadProfile`,
      resetState: `app/resetState`,
    }),
  },
};
</script>
