<template>
  <tr class="bg-blue-50 text-center">
    <td class="px-1 pl-3 font-bold text-left" data-test="recognition__label">
      <span> - </span>
    </td>
    <td class="px-1 py-6 font-bold" data-test="recognition__date">
      {{ recognitionDateStr }}
    </td>
    <td class="px-1" data-test="recognition__status">
      <span class="flex justify-center">
        <course-status :status="COURSE_STATUS.PASSED" :recognized="true" />
      </span>
    </td>
    <td class="px-1" data-test="recognition__grade">
      <recognition-grade
        classes="font-bold"
        :is-graded="recognition.grade.isGraded"
        :grade="recognition.grade.grade"
      />
    </td>
    <td class="px-1" data-test="recognition__comment">
      {{ recognition.legacyComment }}
    </td>
    <td class="px-1">
      <span class="flex gap-2 justify-center">
        <recognized-icon />
      </span>
    </td>
    <td class="px-1 pr-3 text-center" data-test="recognition__link">
      <a v-if="link" :href="link" target="_blank" class="text-cta hover:text-cta-darker">
        <n-icon icon="mdi-link-variant" size="2xl" />
      </a>
      <n-icon v-else icon="mdi-link-variant" size="2xl" class="text-gray-700" />
    </td>
    <td class="px-1 last:pr-3 text-center">
      <n-tooltip
        data-test="delete__recognition__tooltip"
        placement="top"
        content="Diese Aktion ist für transferierte Anerkennungen nicht verfügbar"
        :disabled="!recognition.transferred"
      >
        <n-button
          data-test="delete__recognition__button"
          variant="tertiary"
          :disabled="recognition.transferred"
          @click="isConfirmDeleteRecognitionOpen = true"
        >
          <n-icon icon="mdi-cancel" />
        </n-button>
      </n-tooltip>

      <n-dialog
        :is-open="isConfirmDeleteRecognitionOpen"
        :display-close-button="false"
        subject="Bist du sicher, dass du diesen Eintrag löschen möchtest?"
        @close="isConfirmDeleteRecognitionOpen = false"
      >
        <div class="text-center text-xs text-gray-700 p-4">
          Das Löschen einer Note kann Auswirkungen auf den Studienfortschritt des Studenten haben.
          Dieser Vorgang kann nicht rückgängig gemacht werden.
        </div>
        <template #image>
          <img :src="deleteIcon" alt="Anerkennung löschen" />
        </template>
        <template #actions>
          <n-button
            variant="secondary"
            :disabled="loading"
            @click="isConfirmDeleteRecognitionOpen = false"
            >Abbrechen</n-button
          >
          <n-button
            data-test="cancel__delete__recognition__button"
            variant="primary"
            action-type="reset"
            :disabled="loading"
            class="danger"
            @click="deleteRecognitionAndCloseModal"
            >Löschen</n-button
          >
        </template>
      </n-dialog>
    </td>
  </tr>
</template>

<script>
import { NTooltip, NButton, NIcon, NDialog } from '@careerpartner/nitro';
import CourseStatus from '@/components/common/course-status/CourseStatus.vue';
import RecognitionGrade from '@/components/common/grade/RecognitionGrade.vue';
import RecognizedIcon from '@/components/common/RecognizedIcon.vue';
import { sanitizeUrl } from '@braintree/sanitize-url';
import { mapActions } from 'vuex';
import { ASSET_URLS } from '@/assets/asset-urls';
import { COURSE_STATUS } from '@/components/common/course-status/course-status';

export default {
  name: 'RecognitionTableDetail',
  components: {
    NIcon,
    CourseStatus,
    NTooltip,
    NButton,
    NDialog,
    RecognitionGrade,
    RecognizedIcon,
  },

  props: {
    recognition: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      COURSE_STATUS,
      loading: false,
      isConfirmDeleteRecognitionOpen: false,
      deleteIcon: ASSET_URLS.trashcan,
    };
  },

  computed: {
    recognitionDateStr() {
      const gradeDate = new Date(this.recognition.date);
      const nullDate = new Date('1970-01-01');

      return gradeDate.valueOf() !== nullDate.valueOf()
        ? gradeDate.toLocaleDateString('de-DE', {})
        : '';
    },
    link() {
      return this.recognition.link ? sanitizeUrl(this.recognition.link) : '';
    },
  },

  methods: {
    ...mapActions('recognitions', ['deleteRecognition']),
    async deleteRecognitionAndCloseModal() {
      this.loading = true;
      await this.deleteRecognition(this.recognition.id);
      this.isConfirmDeleteRecognitionOpen = false;
      this.loading = false;
    },
  },
};
</script>
