export const resignationOptions = [
  {
    id: 'CARE_EDITED',
    title: 'Edited in CARE (CARE_EDITED)',
  },
  {
    id: 'EPOS_FEATURE_MISSING',
    title: 'Required feature is missing in EPOS (EPOS_FEATURE_MISSING)',
  },
  {
    id: 'OTHER_REASONS',
    title: 'Other reasons (OTHER_REASONS)',
  },
];
