<template>
  <thead class="h-20 text-white text-left text-lg cursor-pointer">
    <tr class="bg-blue-400 text-center">
      <th
        class="uppercase px-1 pl-3 sticky bg-blue-400 z-10 text-left"
        :class="topClass"
        colspan="2"
      >
        <div class="flex items-center">
          <n-icon class="-ml-2 mr-2" :icon="chevronState" size="3xl"></n-icon>
          <span>{{ name }}</span>
        </div>
      </th>
      <th class="px-1 sticky bg-blue-400" :class="topClass">
        {{ ects }}
      </th>
      <th
        :colspan="isRecognitionModeActive ? '5' : '6'"
        class="sticky bg-blue-400 z-10 pr-3"
        :class="topClass"
      >
        <!--Dummy Header for alignment -->
      </th>
    </tr>
  </thead>
</template>
<script>
import { NIcon } from '@careerpartner/nitro';
import { mapState } from 'vuex';

export default {
  name: 'GradesTableCurriculumTermHead',
  components: {
    NIcon,
  },
  props: {
    ects: {
      type: Number,
      default: 0,
    },
    active: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapState('overview', ['isRecognitionModeActive']),
    chevronState() {
      return this.active ? 'mdi-chevron-down' : 'mdi-chevron-right';
    },
    topClass() {
      if (this.isRecognitionModeActive) {
        return 'top-16';
      }

      return 'top-28';
    },
  },
};
</script>
