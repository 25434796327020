<script setup>
import { NBanner, NButton, NModal, NSelect, NTextarea } from '@careerpartner/nitro';
import { computed, ref } from 'vue';
import GradesLoader from '@/components/common/Loader.vue';
import { StudyProgressAPI } from '@/services/study-progress-api';
import { resignationOptions } from '@/components/overview/ownership/ownershipConstants';

const { studyProgressId } = defineProps({
  studyProgressId: {
    type: String,
    required: true,
  },
  displayModal: Boolean,
});

const emits = defineEmits(['ownershipModalClosed', 'ownershipResigned']);

const resignationReason = ref(null);
const resignationReasonDescription = ref(null);
const loading = ref(false);
const resignationError = ref({
  exists: false,
  message: null,
});

const getErrorMessage = (error) => {
  if (error.response && error.response.data && error.response.data.errors) {
    return error.response.data.errors.reduce((acc, curr) => {
      return (acc += `${curr.message}.`);
    }, '');
  }

  if (error.message) {
    return error.message;
  }

  return null;
};

const resignOwnership = async () => {
  loading.value = true;
  resignationError.value = {
    exists: false,
    message: null,
  };
  try {
    await StudyProgressAPI.resignOwnershipForStudyProgress(
      studyProgressId,
      resignationReason.value,
      resignationReasonDescription.value
    );
    emits('ownershipResigned');
    emits('ownershipModalClosed');
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    resignationError.value.exists = true;
    if (errorMessage) {
      resignationError.value.message = errorMessage;
    }
  }
  loading.value = false;
};

const disableResignButton = computed(() => loading.value || resignationReason.value === null);
</script>

<template>
  <n-modal
    data-test="resignOwnershipModal"
    :is-open="displayModal"
    title="Resign Ownership"
    @close="emits('ownershipModalClosed')"
  >
    <div class="h-full py-5 px-6 flex justify-between flex-col">
      <div v-if="loading">
        <grades-loader />
      </div>
      <div v-else>
        <n-banner
          v-if="resignationError.exists"
          variant="error"
          data-test="resign-ownership-error-banner"
          class="h-full items-start"
        >
          <template #header> Ooops...Something went wrong </template>
          <template #message>
            <p v-if="resignationError.message" data-test="resign-ownership-error-message">
              {{ resignationError.message }}
            </p>
          </template>
        </n-banner>
        <n-banner
          data-test="resign-ownership-warning-banner"
          class="h-full items-start mt-3 mb-4 py-5"
        >
          <template #header>Achtung</template>
          <template #message>
            Der ausgewählte Studienfortschritt der Verwaltung auf CARE kann nicht rückgängig gemacht
            werden. Das ausgewählte Programm kann danach nicht mehr in EPOS verwaltet werden
          </template>
        </n-banner>
        <n-select
          id="resign-ownership-select"
          v-model="resignationReason"
          label="Grund der Verwaltungsumschaltung*"
          searchable
          class="text-left"
          :options="resignationOptions"
          name="resignationReasonName"
          track-by="id"
          option-label="title"
        ></n-select>
        <n-textarea
          v-model="resignationReasonDescription"
          data-test="resignation-reason-description-text"
          label="Beschreibung"
        />
        <div class="flex justify-start mb-4">
          <span class="text-sm text-gray-700">*Mandatory fields</span>
        </div>
      </div>
    </div>

    <template #actions>
      <n-button
        :disabled="disableResignButton"
        data-test="resign-ownership-button"
        @click="resignOwnership"
      >
        VERWALTUNG UMSCHALTEN
      </n-button>
    </template>
  </n-modal>
</template>
