<template>
  <div class="relative mt-10">
    <n-input
      id="searchInput"
      v-model="searchInput"
      data-test="grades-overview__search-input"
      label="Modul-/Fachbezeichnung oder Kürzel eingeben"
      icon="mdi-magnify"
      @input="onSearchInput"
    />
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex';
import debounce from 'lodash.debounce';
import { NInput } from '@careerpartner/nitro';

export default {
  name: 'StudyProgressSearch',
  components: {
    NInput,
  },
  data: () => ({
    searchInput: null,
  }),
  computed: {
    ...mapGetters({
      getSearchTerm: `overview/getSearchTerm`,
    }),
  },
  watch: {
    getSearchTerm(val) {
      if (!val) {
        this.searchInput = '';
      }
    },
  },
  mounted() {
    if (this.getSearchTerm.length) {
      this.searchInput = this.getSearchTerm;
    }
  },
  methods: {
    onSearchInput: debounce(function () {
      this.setSearchTerm(this.searchInput);
    }, 300),
    ...mapMutations({
      setSearchTerm: `overview/setSearchTerm`,
    }),
  },
};
</script>
