export default {
  saveModuleOffers(state, offers) {
    state.moduleOffers = offers;
  },

  saveCourseOffers(state, offers) {
    state.courseOffers = {
      ...state.courseOffers,
      [offers[0]?.curriculumCourseId]: offers,
    };
  },

  reset(state) {
    state.moduleOffers = [];
    state.courseOffers = {};
  },
};
