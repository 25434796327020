<template>
  <n-menu
    :actions="actions"
    alignment="end"
    @click:on-enrol="
      openEnrolment({
        curriculumModule: curriculumModule,
      })
    "
    @click:on-unenrol="
      openUnenrolment({
        curriculumModule: curriculumModule,
        curriculumCourse: curriculumCourse,
      })
    "
  >
  </n-menu>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { NMenu } from '@careerpartner/nitro';
export default {
  name: 'CourseAndModuleActions',
  components: {
    NMenu,
  },

  props: {
    curriculumModule: {
      type: Object,
      required: true,
    },
    curriculumCourse: {
      type: Object,
      default: null,
    },
  },

  computed: {
    ...mapGetters({
      doesStudyProgressAllowEnrolment: 'studyProgresses/doesStudyProgressAllowEnrolment',
      isTicketUnenrolable: 'grades/isTicketUnenrolable',
      getActiveStudyProgress: 'studyProgresses/getSelectedStudyProgress',
      isCurriculumModuleUnenrolable: 'enrolments/isCurriculumModuleUnenrolable',
      isCurriculumCourseUnenrolable: 'enrolments/isCurriculumCourseUnenrolable',
    }),

    id() {
      return this.curriculumCourse ? this.curriculumCourse.id : this.curriculumModule.id;
    },

    hasEnrolment() {
      return !!(this.curriculumCourse
        ? this.curriculumCourse?.courseProgress?.courseEnrolment
        : this.curriculumModule?.moduleProgress?.moduleEnrolment);
    },

    hasRecognition() {
      return !!(this.curriculumCourse
        ? this.curriculumCourse?.courseProgress?.recognition
        : this.curriculumModule?.moduleProgress?.recognition);
    },

    isUnenrolable() {
      if (this.curriculumCourse) {
        return this.isCurriculumCourseUnenrolable(this.curriculumCourse);
      } else if (this.curriculumModule) {
        return this.isCurriculumModuleUnenrolable(this.curriculumModule);
      }

      return false;
    },

    actions() {
      return [
        {
          name: 'onEnrol',
          text: 'Einbuchen',
          icon: 'mdi-account-plus-outline',
          disabled: this.hasEnrolment || this.hasRecognition,
        },
        {
          name: 'onUnenrol',
          text: 'Ausbuchen',
          icon: 'mdi-account-minus-outline',
          disabled: !this.isUnenrolable,
        },
      ];
    },
  },

  methods: {
    ...mapMutations({
      openEnrolment: 'overview/openEnrolment',
      openUnenrolment: 'overview/openUnenrolment',
    }),
  },
};
</script>
