import { GeneralExceptionCodes } from './api-exceptions';
import { API_ERROR_MAP, GATEWAY_ERROR_MAP, NETWORK_ERROR_MAP } from './error-message-map';

export const FALLBACK_ERROR_MESSAGE = API_ERROR_MAP[GeneralExceptionCodes.UNKNOWN_EXCEPTION];

export function isGatewayError(error) {
  return !!error.response?.data?.message;
}

export function isApiError(error) {
  return Array.isArray(error.response?.data?.errors);
}

export function isNetworkError(error) {
  return !!NETWORK_ERROR_MAP[error.code];
}

function insertName(message, name) {
  if (!name) throw new Error('Name required to generate error message.');
  return message.replace('<Name>', name);
}

export function mapErrorMessage(error, name) {
  let result;

  if (error.name !== 'AxiosError') return error.message;

  if (isNetworkError(error)) {
    result = NETWORK_ERROR_MAP[error.code];
  }

  if (isGatewayError(error)) {
    const gatewayError = error.response?.data;
    result = GATEWAY_ERROR_MAP[gatewayError.message?.toUpperCase()];
  }

  if (isApiError(error)) {
    const apiError = error.response?.data?.errors[0];
    result = API_ERROR_MAP[apiError?.code];
  }

  if (result) {
    if (typeof result === 'object') {
      return insertName(result.message, name ?? result.default);
    }
    return result;
  } else {
    return FALLBACK_ERROR_MESSAGE;
  }
}

export function extractErrorDescription(error) {
  if (error.name !== 'AxiosError') return null;

  if (error.response) {
    let data = 'No additional data';

    if (typeof error.response.data === 'string') {
      data = error.response.data;
    }

    if (typeof error.response.data === 'object') {
      data = JSON.stringify(error.response.data);
    }
    return `Response status: ${error.response.status}. Message: ${error.message}. Data: ${data}.`;
  }

  return error.message;
}
