<template>
  <tr data-test="grades-overview__course" class="h-20 text-center">
    <td class="px-1 pl-3 text-left">
      <div class="flex">
        <n-checkbox
          v-if="isRecognitionModeActive"
          :id="curriculumCourse.id"
          v-model="isSelectedForRecognition"
          :disabled="!isRecognizable"
          class="resize-wrapped-input no-padding-wrapped-span"
        />
        <router-link
          v-if="!isRecognitionModeActive"
          :to="{
            name: 'COURSE_GRADES',
            params: {
              bookingId: getSelectedStudyProgress.id,
              courseId: curriculumCourse.id,
            },
          }"
          data-test="grade-overview-course__name"
          class="grades-course-name truncate"
        >
          {{ curriculumCourse.courseTitle }}
        </router-link>
        <p
          v-else
          class="grades-course-name select-none truncate"
          :class="isRecognizable ? 'cursor-pointer' : ''"
          @click="toggleCourseSelectedForRecognition"
        >
          {{ curriculumCourse.courseTitle }}
        </p>
      </div>
      <p
        data-test="grade-overview-course__version"
        class="text-xs text-gray-700"
        :class="isRecognitionModeActive ? 'ml-[4.5rem]' : 'ml-11'"
      >
        Version: {{ curriculumCourseVersion(curriculumCourse) }}
      </p>
    </td>
    <td class="px-1" data-test="grade-overview-course__code">
      {{ curriculumCourse.curriculumCourseCode }}
    </td>
    <td data-test="grade-overview-course__ects" class="px-1">
      {{ curriculumCourse.ects }}
    </td>
    <td class="px-1" data-test="grade-overview-course__status">
      <span class="flex justify-center">
        <course-status
          v-if="curriculumCourse.courseProgress"
          :id="curriculumCourse.id"
          :status="curriculumCourse.courseProgress.status"
        />
      </span>
    </td>
    <td class="px-1" data-test="grade-overview-course__last-valid-graduation">
      <course-grade
        v-if="!recognized && latestExamResult && latestExamResult.grade"
        :status="latestExamResult.status"
        :is-graded="latestExamResult.grade.isGraded"
        :grade="latestExamResult.grade.grade"
        :score="latestExamResult.score"
        :score-max="latestExamResult.maxPossibleScore"
      />
      <recognition-grade
        v-else-if="recognized && recognition"
        :is-graded="recognition.grade.isGraded"
        :grade="recognition.grade.grade"
      />
    </td>
    <td class="px-1" data-test="grade-overview-course__attempts">
      <span v-if="recognized"> &mdash; </span>
      <span v-else-if="tryCount"> {{ tryCount }}. Versuch </span>
    </td>
    <td class="px-1">
      <span class="flex gap-2 justify-center">
        <transferred-icon v-if="transferred" />
        <recognized-icon v-if="recognized" />
      </span>
    </td>
    <td class="px-1 text-center" data-test="grade-overview-course__link">
      <a
        v-if="recognized && !!recognitionLink"
        :href="recognitionLink"
        target="_blank"
        class="text-cta hover:text-cta-darker"
      >
        <n-icon icon="mdi-link-variant" size="2xl" />
      </a>
    </td>
    <td
      v-if="!isRecognitionModeActive"
      data-test="grades-overview-course__action"
      class="text-right px-1 pr-4"
    >
      <course-and-module-actions
        :curriculum-module="curriculumModule"
        :curriculum-course="curriculumCourse"
      ></course-and-module-actions>
    </td>
  </tr>
</template>
<script>
import { mapGetters, mapState, mapMutations } from 'vuex';
import CourseStatus from '@/components/common/course-status/CourseStatus.vue';
import CourseGrade from '@/components/common/grade/CourseGrade.vue';
import RecognitionGrade from '@/components/common/grade/RecognitionGrade.vue';
import CourseAndModuleActions from './CourseAndModuleActions.vue';
import { NIcon, NCheckbox } from '@careerpartner/nitro';
import { sanitizeUrl } from '@braintree/sanitize-url';
import RecognizedIcon from '@/components/common/RecognizedIcon.vue';
import TransferredIcon from '@/components/common/TransferredIcon.vue';

export default {
  name: 'GradesTableCurriculumCourse',
  components: {
    CourseStatus,
    CourseGrade,
    RecognitionGrade,
    CourseAndModuleActions,
    NIcon,
    NCheckbox,
    RecognizedIcon,
    TransferredIcon,
  },

  props: {
    curriculumModule: {
      type: Object,
      required: true,
    },
    curriculumCourse: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapGetters('studyProgresses', [
      'getSelectedStudyProgress',
      'curriculumCourseVersion',
      'getLatestExamResult',
      'getTryCount',
    ]),
    ...mapGetters('overview', ['isCourseSelectedForRecognition']),
    ...mapState('overview', ['isRecognitionModeActive']),

    version() {
      const startTerm = this.curriculumCourse?.startTerm ?? '';
      const startYear = this.curriculumCourse?.startYear ?? '';
      return `${startTerm} ${startYear}`.trim();
    },

    latestExamResult() {
      return this.getLatestExamResult(this.curriculumCourse);
    },

    tryCount() {
      return this.getTryCount(this.curriculumCourse);
    },

    transferred() {
      return !!this.curriculumCourse.courseProgress?.transferred;
    },

    recognized() {
      return !!this.curriculumCourse.courseProgress?.recognized;
    },

    recognition() {
      return this.curriculumCourse.courseProgress?.recognition;
    },

    recognitionLink() {
      const link = this.curriculumCourse.courseProgress?.recognition?.link;
      return link ? sanitizeUrl(link) : '';
    },

    isRecognizable() {
      return (
        !this.curriculumCourse.courseProgress?.courseEnrolment &&
        !this.curriculumCourse.courseProgress?.recognition
      );
    },

    isSelectedForRecognition: {
      get() {
        return this.isCourseSelectedForRecognition(this.curriculumCourse.id);
      },
      set(newValue) {
        if (newValue) {
          this.addCourseRecognitionOptions(this.curriculumCourse.id);
        } else {
          this.removeCourseRecognitionOptions(this.curriculumCourse.id);
        }
      },
    },
  },

  methods: {
    ...mapMutations('overview', ['addCourseRecognitionOptions', 'removeCourseRecognitionOptions']),
    toggleCourseSelectedForRecognition() {
      if (this.isRecognizable) {
        const isCourseSelected = this.isCourseSelectedForRecognition(this.curriculumCourse.id);

        if (isCourseSelected) {
          this.removeCourseRecognitionOptions(this.curriculumCourse.id);
        } else {
          this.addCourseRecognitionOptions(this.curriculumCourse.id);
        }
      }
    },
  },
};
</script>

<style lang="postcss">
.no-padding-wrapped-span > span {
  padding: 0;
}
.resize-wrapped-input > input {
  width: 17px;
  height: 17px;
}
.grades-course-name::before {
  content: '';
  @apply inline-block w-5 border-t-2 border-blue-300 mx-3 flex-shrink-0;
}
</style>
