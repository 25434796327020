export default {
  setSnackbarMessage(state, message) {
    state.snackbar.message = message;
  },
  setProfile(state, profile) {
    state.profile = profile;
  },
  clearErrors(state) {
    state.errors = [];
  },
  pushToErrorStack(state, payload) {
    state.errors.push(payload);
  },
  removeErrorFromStack(state) {
    state.errors.shift();
  },
};
