<template>
  <div class="flex flex-col items-center text-center max-w-xs">
    <img :src="imgSrc" height="160" alt="" aria-hidden="true" />
    <p class="font-bold mt-6">Deine Suche ergab keine Treffer.</p>
    <p class="mt-4 text-gray-600">
      <slot> Überprüfe Deine Suchanfrage auf Tippfehler oder setze Filter zurück. </slot>
    </p>
    <n-button variant="primary" class="mt-4" @click="$emit('reset-search')"
      >Suche zurücksetzen</n-button
    >
  </div>
</template>

<script>
import { NButton } from '@careerpartner/nitro';

export default {
  name: 'EmptySearchResult',
  components: {
    NButton,
  },
  emits: ['reset-search'],
  data() {
    return {
      imgSrc:
        'https://res.cloudinary.com/iubh/image/upload/v1633072017/IT%20-%20Epos%20/illustrations/epos-no-search-results.svg',
    };
  },
};
</script>
