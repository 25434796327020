import singleSpaVue from 'single-spa-vue';
import { createApp, h } from 'vue';

import App from '@/App.vue';
import { useNitro } from '@/plugins/nitro';
import { usePermissions } from '@/plugins/permissions';
import { useTranslations } from '@/plugins/translations';
import { store } from '@/store';
import { router } from './plugins/router';

const CONTAINER_SELECTOR = '#app-placeholder';
const vueLifecycles = singleSpaVue({
  createApp,
  replaceMode: true,
  appOptions: {
    render() {
      // single-spa props are available on the "this" object. Forward them to your component as needed.
      // https://single-spa.js.org/docs/building-applications#lifecycle-props
      return h(App, { name: this.name });
    },
    el: CONTAINER_SELECTOR,
  },
  handleInstance(app) {
    console.info('grades', import.meta.env.GIT_TAG, import.meta.env.GIT_COMMIT_HASH);

    app.config.performance = true;
    app.use(store);
    const i18n = useTranslations(app);
    useNitro(app, i18n);
    app.use(router);
    usePermissions(app);
  },
});

export const bootstrap = vueLifecycles.bootstrap;
export const mount = vueLifecycles.mount;
export const unmount = vueLifecycles.unmount;

export const devtools = {
  overlays: {
    selectors: [CONTAINER_SELECTOR],
  },
};

export default {
  bootstrap,
  mount,
  unmount,
  devtools,
};
