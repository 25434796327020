import CourseDetail from '@/views/CourseDetail.vue';
import NotFound from '@/views/NotFound.vue';
import ProgressList from '@/views/ProgressList.vue';
import ProgressOverview from '@/views/ProgressOverview.vue';
import Transfer from '@/views/Transfer.vue';
import { ROUTES } from '@epos/orchestrator';
import { COURSE_GRADES, NOT_FOUND, OVERVIEW, TRANSFER } from '@/common/constants/router';

export const routes = [
  {
    path: `/services/customers/:id/grades`,
    name: 'PROGRESS_INDEX',
    component: ProgressList,
  },
  {
    path: '/services/customers/:id/grades/booking/:bookingId',
    name: OVERVIEW,
    component: ProgressOverview,
  },
  {
    path: '/services/customers/:id/grades/booking/:bookingId/transfer',
    name: TRANSFER,
    component: Transfer,
  },
  {
    path: ROUTES.CUSTOMER_SERVICES.COURSE_GRADES,
    name: COURSE_GRADES,
    component: CourseDetail,
  },
  // Expected routes to Academic Profile MFE - suppresses the Vue router warning
  {
    path: `/services/customers/:id/profile`,
    name: 'ACADEMIC_PROFILE_PROFILE',
  },
  {
    path: `/services/customers/:id/booking`,
    name: 'ACADEMIC_PROFILE_BOOKING',
  },
  {
    path: `/services/customers/:id/statistics`,
    name: 'ACADEMIC_PROFILE_STATISTICS',
  },
  {
    path: `${ROUTES.CUSTOMER_SERVICES.GRADES}/not-found`,
    name: NOT_FOUND,
    component: NotFound,
  },
];
