<template>
  <grades-table class="mt-10" data-cy="grades-detail-table">
    <grades-table-head :column-config="columnConfig" />
    <tbody v-if="recognized">
      <recognition-table-detail :recognition="recognition" class="odd:bg-blue-100" />
    </tbody>
    <tbody v-else-if="!recognized && latestExamResult">
      <grades-table-detail
        v-for="examResult of reversedExamResults"
        :key="examResult.id"
        :exam-result="examResult"
      />
    </tbody>
  </grades-table>
</template>
<script>
import { mapGetters } from 'vuex';
import GradesTable from '@/components/common/GradesTable.vue';
import GradesTableHead from '@/components/common/GradesTableHead.vue';
import GradesTableDetail from '@/components/detail/GradesTableDetail.vue';
import RecognitionTableDetail from '@/components/detail/RecognitionTableDetail.vue';

export default {
  name: 'GradesDetail',
  components: {
    GradesTable,
    GradesTableHead,
    GradesTableDetail,
    RecognitionTableDetail,
  },

  props: {
    curriculumCourse: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapGetters('studyProgresses', ['getLatestExamResult']),

    reversedExamResults() {
      return this.curriculumCourse.courseProgress?.examResults?.slice().reverse();
    },

    latestExamResult() {
      return this.getLatestExamResult(this.curriculumCourse);
    },

    recognized() {
      return !!this.curriculumCourse.courseProgress?.recognized;
    },

    recognition() {
      return this.curriculumCourse.courseProgress?.recognition;
    },

    columnConfig() {
      return [
        { label: 'Versuch', class: 'max-w-[24rem] w-full' },
        { label: 'Datum', class: 'w-[8rem]' },
        { label: 'Status', class: 'w-[8rem]' },
        { label: 'Note', class: 'w-[8rem]' },
        { label: 'Kommentar', class: 'w-[8rem]' },
        { label: 'Herkunft', class: 'w-[6rem]' },
        ...(this.recognized ? [{ label: 'Link', class: 'w-[6rem] pr-5' }] : []),
        ...(this.recognized ? [{ label: 'Aktion', class: 'w-[6rem]' }] : []),
      ];
    },
  },
};
</script>
