<template>
  <tr class="mb-7 h-20 align-top">
    <td class="pr-10">
      <n-input
        id="courseName"
        :model-value="selectedCourseRecognitionOptions.curriculumCourse.courseTitle"
        :disabled="true"
        label="Name des Kurses"
        :errors="
          selectedCourseRecognitionOptions.error
            ? [selectedCourseRecognitionOptions.error]
            : undefined
        "
      />
    </td>
    <td class="pr-10">
      <n-select
        id="recognitionGradStateSelect"
        :model-value="selectedCourseRecognitionOptions.recognitionGradeState"
        required
        open-direction="bottom"
        :options="[
          'Bestanden',
          '1,0',
          '1,3',
          '1,7',
          '2,0',
          '2,3',
          '2,7',
          '3,0',
          '3,3',
          '3,7',
          '4,0',
        ]"
        label="Note"
        :multiple="false"
        @update:model-value="
          setCourseRecognitionGradeState({
            curriculumCourseId: selectedCourseRecognitionOptions.curriculumCourse.id,
            recognitionGradeState: $event,
          })
        "
      />
    </td>
    <td class="pr-10">
      <div class="flex flex-row items-baseline">
        <n-input
          id="confirmationLink"
          class="w-11/12"
          label="Link"
          :errors="
            showInvalidLinkErrorDebounced
              ? ['Link ist nicht gültig (Format: http[s]://careerpartner.lightning.force.com/*)']
              : undefined
          "
          :model-value="selectedCourseRecognitionOptions.link"
          @update:model-value="
            handleLinkInput(selectedCourseRecognitionOptions.curriculumCourse.id, $event)
          "
        />
        <n-button
          variant="quaternary"
          class="w-1/12 ml-4 flex h-6"
          @click="
            removeCourseRecognitionOptions(selectedCourseRecognitionOptions.curriculumCourse.id)
          "
        >
          <n-icon icon="close" class="text-error" />
        </n-button>
      </div>
    </td>
  </tr>
</template>
<script>
import { NInput, NSelect, NIcon, NButton } from '@careerpartner/nitro';
import { mapMutations } from 'vuex';
import debounce from 'lodash.debounce';
import { isValidRecognitionLink } from './helper';
export default {
  name: 'RecognitionTableRow',
  components: {
    NInput,
    NSelect,
    NIcon,
    NButton,
  },
  props: {
    selectedCourseRecognitionOptions: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showInvalidLinkErrorDebounced: false,
    };
  },
  methods: {
    ...mapMutations('overview', [
      'removeCourseRecognitionOptions',
      'setCourseRecognitionGradeState',
      'setCourseRecognitionLink',
    ]),
    handleLinkInput(curriculumCourseId, link) {
      this.setCourseRecognitionLink({
        curriculumCourseId,
        link,
      });
      this.showInvalidLinkErrorDebounced = false;
      this.updateLinkErrorDebounced(!isValidRecognitionLink(link));
    },
    updateLinkErrorDebounced: debounce(function (showError) {
      this.showInvalidLinkErrorDebounced = showError;
    }, 900),
  },
};
</script>
