<template>
  <tr
    v-show="active"
    data-test="grades-overview__curriculum-structure-element"
    class="h-20 bg-blue-200 font-bold"
  >
    <td class="pl-3" :colspan="isRecognitionModeActive ? '8' : '9'">
      <p>
        {{ name }}
      </p>
    </td>
  </tr>
</template>
<script>
import { mapState } from 'vuex';

export default {
  name: 'GradesTableCurriculumStructureElementHead',
  props: {
    active: {
      type: Boolean,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapState('overview', ['isRecognitionModeActive']),
  },
};
</script>
