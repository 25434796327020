<template>
  <div class="w-full flex flex-col pt-4 items-center">
    <n-simple-loader />
    <p class="text-xs text-cta">
      <slot> Wird geladen... </slot>
    </p>
  </div>
</template>

<script>
import { NSimpleLoader } from '@careerpartner/nitro';
export default {
  name: 'GradesLoader',
  components: {
    NSimpleLoader,
  },
};
</script>
