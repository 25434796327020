import { NOT_FOUND, OVERVIEW } from '@/common/constants/router';
import { router } from '@/plugins/router';
import { StudentEnrolmentApi } from '@/services/student-enrolment-api';

export default {
  async loadProfile({ commit, dispatch }, profileId) {
    try {
      const { data } = await StudentEnrolmentApi.getProfileById(profileId);

      commit('setProfile', data);
      return data;
    } catch (e) {
      dispatch('setError', e);
      return false;
    }
  },

  setError({ commit }, error) {
    commit('pushToErrorStack', error);
  },
  dismissError({ commit }) {
    commit('removeErrorFromStack');
  },

  resetState({ commit }) {
    commit('app/setProfile', null, { root: true });
    commit('app/clearErrors', null, { root: true });
    commit('overview/resetOverview', null, { root: true });
    commit('studyProgresses/reset', null, { root: true });
  },

  async navigateToOverview(context) {
    await router.push({
      name: OVERVIEW,
      params: {
        id: context.getters.getProfile.id,
        bookingId: context.rootGetters['studyProgresses/getSelectedStudyProgress'].id,
      },
    });
  },

  async navigateToNotFound() {
    await router.push({
      name: NOT_FOUND,
    });
  },
};
