import '@careerpartner/nitro/style.css';
import '@/assets/base.css';
import '@/assets/tailwind.css';
import { type App } from 'vue';
import { type I18n } from 'vue-i18n';

import { initialize } from '@careerpartner/nitro';

export const useNitro = (app: App, i18n: I18n) => {
  app.use(initialize, i18n);
};
