<template>
  <n-tooltip placement="top" :content="getStatusText">
    <div :class="`${getChipColor} flex w-7 h-7 rounded-sm test`">
      <n-icon :icon="getStatusIcon" class="m-auto text-white" />
    </div>
  </n-tooltip>
</template>
<script>
import { MODULE_STATUS } from '@/components/common/module-status/module-status';
import { NTooltip, NIcon } from '@careerpartner/nitro';

export default {
  name: 'ModuleStatus',
  components: { NTooltip, NIcon },
  props: {
    status: {
      type: String,
      required: true,
    },
  },
  computed: {
    getStatusText() {
      switch (this.status) {
        case MODULE_STATUS.ENROLED:
        case MODULE_STATUS.FAILED:
          return 'In Modul eingebucht';
        case MODULE_STATUS.PASSED:
          return 'Bestanden';
        default:
          return '';
      }
    },
    getStatusIcon() {
      switch (this.status) {
        case MODULE_STATUS.ENROLED:
        case MODULE_STATUS.FAILED:
          return 'mdi-bank-outline';
        case MODULE_STATUS.PASSED:
          return 'mdi-check';
        default:
          return '';
      }
    },
    getChipColor() {
      switch (this.status) {
        case MODULE_STATUS.ENROLED:
        case MODULE_STATUS.FAILED:
          return 'bg-gray-600';
        case MODULE_STATUS.PASSED:
          return 'bg-success';
        default:
          return '';
      }
    },
  },
};
</script>
