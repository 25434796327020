<template>
  <n-tooltip v-if="grade && isGraded" placement="top">
    <span :class="classes">
      {{ formatGrade(grade) }}
    </span>
    <template #content>
      <span>{{ tooltipContent }}</span>
    </template>
  </n-tooltip>
  <span v-else-if="grade && !isGraded" :class="classes">
    {{ isPassed ? 'Best.' : 'N. Best.' }}
  </span>
</template>

<script>
import { NTooltip } from '@careerpartner/nitro';
import { EXAM_RESULT_STATUS } from './exam-result-status';
import { formatGrade } from '@/common/utils/format';

export default {
  name: 'CourseGrade',
  components: { NTooltip },
  props: {
    classes: {
      type: String,
      required: false,
      default: '',
    },
    status: {
      type: String,
      required: true,
    },
    isGraded: {
      type: Boolean,
      required: true,
    },
    grade: {
      type: Number,
      required: false,
      default: null,
    },
    score: {
      type: Number,
      default: null,
    },
    scoreMax: {
      type: Number,
      default: null,
    },
  },
  computed: {
    isPassed() {
      return this.status === EXAM_RESULT_STATUS.PASSED;
    },
    tooltipContent() {
      if (this.score !== null && this.scoreMax !== null) {
        return `${this.score}/${this.scoreMax} Punkten`;
      }

      return 'Punkte nicht verfügbar';
    },
  },
  methods: {
    formatGrade,
  },
};
</script>
