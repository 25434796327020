<template>
  <div>
    <grades-loader v-if="loading && !hasStudyProgresses">{{ loading }}</grades-loader>
    <div v-else>
      <grades-tool-bar />
      <OwnershipBanner
        :profile-academy-id="profileAcademyId"
        :study-progress="getSelectedStudyProgress"
        @resign-ownership-initiated="displayOwnershipModal = true"
      />
      <stat-bar />
      <programs-panel />
      <grades-loader v-if="loading && hasStudyProgresses">{{ loading }}</grades-loader>
      <div v-else>
        <study-progress-search />
        <grades-overview />
        <scroll-to-top-button />
      </div>
    </div>

    <enrolment-modal
      v-if="isEnrolmentOpen"
      :curriculum-module="selectedCurriculumModule"
    ></enrolment-modal>
    <unenrolment-modal
      v-if="isUnenrolmentOpen"
      :curriculum-module="selectedCurriculumModule"
      :curriculum-course="selectedCurriculumCourse"
    >
    </unenrolment-modal>
    <ResignOwnershipModal
      v-if="studyProgressId !== null"
      :study-progress-id="studyProgressId"
      :display-modal="displayOwnershipModal"
      @ownership-modal-closed="displayOwnershipModal = false"
      @ownership-resigned="fetchDataForSelectedStudyProgress"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import GradesOverview from '@/components/overview/GradesOverview.vue';
import StatBar from '@/components/overview/StatBar.vue';
import ProgramsPanel from '@/components/overview/ProgramsPanel.vue';
import ScrollToTopButton from '@/components/common/ScrollToTopButton.vue';
import StudyProgressSearch from '@/components/common/StudyProgressSearch.vue';
import GradesLoader from '@/components/common/Loader.vue';
import pageTitleMixin from '@/common/mixins/page-title';
import GradesToolBar from '@/components/common/GradesToolbar.vue';
import EnrolmentModal from '@/components/overview/enrolment/EnrolmentModal.vue';
import UnenrolmentModal from '@/components/overview/unenrolment/UnenrolmentModal.vue';
import OwnershipBanner from '@/components/overview/ownership/OwnershipBanner.vue';
import ResignOwnershipModal from '@/components/overview/ownership/ResignOwnershipModal.vue';

export default {
  name: 'ProgressOverview',
  components: {
    ResignOwnershipModal,
    OwnershipBanner,
    GradesOverview,
    GradesLoader,
    ProgramsPanel,
    ScrollToTopButton,
    StatBar,
    StudyProgressSearch,
    GradesToolBar,
    EnrolmentModal,
    UnenrolmentModal,
  },
  mixins: [pageTitleMixin],

  data() {
    return {
      studyProgresses: [],
      pageTitle: 'Studienfortschritt',
      studyProgressId: null,
      displayOwnershipModal: false,
    };
  },

  computed: {
    ...mapState('studyProgresses', ['loading', 'selectedStudent']),
    ...mapGetters({
      profileAcademyId: `app/getAcademyId`,
      hasStudyProgresses: `studyProgresses/hasStudyProgresses`,
      getSelectedStudyProgress: `studyProgresses/getSelectedStudyProgress`,
      isEnrolmentOpen: 'overview/isEnrolmentOpen',
      isUnenrolmentOpen: 'overview/isUnenrolmentOpen',
      selectedCurriculumModule: 'overview/selectedCurriculumModule',
      selectedCurriculumCourse: 'overview/selectedCurriculumCourse',
    }),
  },

  async beforeMount() {
    const studentId = this.$route.params.id;
    this.studyProgressId = this.$route.params.bookingId;
    if (this.selectedStudent !== studentId) {
      this.reset();
      this.setSelectedStudent(studentId);
    }

    if (!this.hasStudyProgresses) {
      this.studyProgresses = await this.fetchStudyProgresses(this.profileAcademyId);
    }

    await this.setSelectedStudyProgress(this.studyProgressId);
  },

  methods: {
    ...mapActions({
      fetchStudyProgresses: `studyProgresses/fetchStudyProgresses`,
      fetchDataForSelectedStudyProgress: 'studyProgresses/fetchDataForSelectedStudyProgress',
      setSelectedStudyProgress: `studyProgresses/setSelectedStudyProgress`,
    }),
    ...mapMutations({
      setSelectedStudent: 'studyProgresses/setSelectedStudent',
      reset: 'studyProgresses/reset',
    }),
  },
};
</script>
