import { StudyProgressAPI } from '@/services/study-progress-api';

export default {
  disableRecognitionMode({ commit }) {
    commit('setRecognitionMode', false);
    commit('removeAllCourseRecognitionOptions');
    commit('removeAllSuccessfulCourseRecognitions');
  },

  createRecognition(_helpers, { studyProgressId, curriculumCourseId, isGraded, grade, link }) {
    return StudyProgressAPI.createRecognition({
      studyProgressId,
      curriculumCourseId,
      isGraded,
      grade,
      link,
    });
  },
};
