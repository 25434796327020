import { createApiClient } from '@epos/orchestrator/api';

const ApiClient = createApiClient({ basePath: '/studycontext' });

// https://careerpartner.atlassian.net/wiki/spaces/E/pages/2293530901/Study+Progress
export const STUDY_PROGRESS_ENDPOINT_V1 = '/v1';
export const STUDY_PROGRESS_ENDPOINT_V2 = '/v2';
export const STUDY_PROGRESS_ENDPOINT_V3 = '/v3';

// https://api-dev.iu.org/studycontext/v3/study-progresses
const getStudyProgressesForUser = (careUserId) =>
  ApiClient.instance.get(
    `${STUDY_PROGRESS_ENDPOINT_V3}/study-progresses?filter%5BcareUserId%5D=${careUserId}`
  );
const getStudyProgress = (studyProgressId) =>
  ApiClient.instance.get(`${STUDY_PROGRESS_ENDPOINT_V3}/study-progresses/${studyProgressId}`);

const createModuleEnrolment = (payload) =>
  ApiClient.instance.post(`${STUDY_PROGRESS_ENDPOINT_V2}/module-enrolments`, payload);
const deleteModuleEnrolment = (moduleEnrolmentId) =>
  ApiClient.instance.delete(`${STUDY_PROGRESS_ENDPOINT_V2}/module-enrolments/${moduleEnrolmentId}`);

const createCourseEnrolment = (payload) =>
  ApiClient.instance.post(`${STUDY_PROGRESS_ENDPOINT_V2}/course-enrolments`, payload);
const deleteCourseEnrolment = (courseEnrolmentId) =>
  ApiClient.instance.delete(`${STUDY_PROGRESS_ENDPOINT_V2}/course-enrolments/${courseEnrolmentId}`);

const createRecognition = (payload) =>
  ApiClient.instance.put(`${STUDY_PROGRESS_ENDPOINT_V1}/recognitions`, payload);
const deleteRecognition = (recognitionId) =>
  ApiClient.instance.delete(`${STUDY_PROGRESS_ENDPOINT_V1}/recognitions/${recognitionId}`);

const getModuleOffersForModule = (curriculumModuleId, moduleMainVersionId) => {
  const searchParams = new URLSearchParams();
  if (moduleMainVersionId) {
    searchParams.append('moduleMainVersionId', moduleMainVersionId);
  } else {
    searchParams.append('curriculumModuleId', curriculumModuleId);
  }
  return ApiClient.instance.get(
    `${STUDY_PROGRESS_ENDPOINT_V3}/product-offers/module-offers?${searchParams}`
  );
};

const getCourseOffersForCourse = (curriculumCourseId, courseMainVersionId) => {
  const searchParams = new URLSearchParams();
  if (courseMainVersionId) {
    searchParams.append('courseMainVersionId', courseMainVersionId);
  } else {
    searchParams.append('curriculumCourseId', curriculumCourseId);
  }

  return ApiClient.instance.get(
    `${STUDY_PROGRESS_ENDPOINT_V3}/product-offers/course-offers?${searchParams}`
  );
};

const createTransfer = (payload) =>
  ApiClient.instance.post(`${STUDY_PROGRESS_ENDPOINT_V1}/transfer`, payload);

const deleteTransfer = (courseEnrolmentId) =>
  ApiClient.instance.delete(`${STUDY_PROGRESS_ENDPOINT_V1}/transfer/${courseEnrolmentId}`);

const deleteTransferredRecognition = (transferredRecognitionId) =>
  ApiClient.instance.delete(
    `${STUDY_PROGRESS_ENDPOINT_V1}/transferred-recognitions/${transferredRecognitionId}`
  );

const getOwnershipForStudyProgress = (studyProgressId) =>
  ApiClient.instance.get(
    `${STUDY_PROGRESS_ENDPOINT_V3}/study-progresses/${studyProgressId}/ownership`
  );

const resignOwnershipForStudyProgress = (
  studyProgressId,
  resignationReason,
  resignationReasonDescription
) =>
  ApiClient.instance.patch(
    `${STUDY_PROGRESS_ENDPOINT_V3}/study-progresses/${studyProgressId}/ownership`,
    { resignationReason, resignationReasonDescription }
  );

export const StudyProgressAPI = {
  getStudyProgressesForUser,
  getStudyProgress,
  createCourseEnrolment,
  createModuleEnrolment,
  deleteCourseEnrolment,
  deleteModuleEnrolment,
  deleteRecognition,
  createRecognition,
  getCourseOffersForCourse,
  getModuleOffersForModule,
  createTransfer,
  deleteTransfer,
  deleteTransferredRecognition,
  getOwnershipForStudyProgress,
  resignOwnershipForStudyProgress,
};
