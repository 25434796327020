<template>
  <thead>
    <tr>
      <th
        v-for="(label, idx) of columnConfig"
        :key="idx"
        class="sticky z-10 px-1 pt-6 pb-4 first:pl-3 last:pr-3 text-blue-600 uppercase bg-blue-50 shadow-table-header"
        :class="[
          label.class,
          isRecognitionModeActive ? 'top-0' : 'top-12',
          idx === 0 ? 'text-left' : 'text-center',
        ]"
      >
        {{ label.label }}
      </th>
    </tr>
  </thead>
</template>
<script>
import { mapState } from 'vuex';
export default {
  name: 'GradesTableHead',
  props: {
    columnConfig: {
      type: Array,
      required: true,
    },
  },
  computed: {
    ...mapState('overview', ['isRecognitionModeActive']),
  },
};
</script>
<style scoped lang="postcss">
th {
  @apply border-b border-gray-700;
}
th:first-of-type {
  @apply pl-3;
}
th:last-of-type {
  @apply pr-3;
}
</style>
