<template>
  <div class="w-full flex justify-center mt-12 flex-col">
    <p class="font-bold uppercase">
      <em class="inline-block rounded-full bg-blue-600 text-white not-italic w-6 text-center mr-1"
        >1</em
      >
      Program Auswählen
    </p>
    <n-select
      id="study-progress-select"
      class="mt-8"
      label="Studienfortschritt"
      :options="studyProgressesMapped"
      option-label="title"
      track-by="id"
      :model-value="sourceStudyProgress ?? undefined"
      @update:model-value="getStudyProgressCourses"
    >
    </n-select>

    <template v-if="sourceStudyProgress">
      <p class="font-bold uppercase mt-16">
        <em class="inline-block rounded-full bg-blue-600 text-white not-italic w-6 text-center mr-1"
          >2</em
        >
        Leistungen Transferieren
      </p>
      <n-table :header-items="columnConfig" :data="coursesFromSourceCurriculum">
        <template #row="{ item }">
          <transfer-table-row
            :item="item"
            :transfer-errors="errors"
            :courses-to-transfer="transferOptionsFiltered"
            :existing-transfers="existingTransfers"
            class="odd:bg-blue-100"
            @select-course="selectCourseToTransfer($event, item)"
            @remove-course="removeCourseToTransfer($event)"
            @delete-transfer="initiateDeleteTransfer($event)"
          >
          </transfer-table-row>
        </template>
      </n-table>
      <span class="ml-auto font-bold">
        Gesamtanzahl transferierter ECTS-Punkte:
        <span class="text-cta ml-1">
          <n-icon class="align-middle" icon="transfer-right" size="xl"></n-icon>
          {{ transferredEcts }}
        </span>
      </span>
      <div class="ml-auto mt-12">
        <n-button
          variant="primary"
          :disabled="selectedTransfers.length == 0 || isLoading"
          @click="transferCourses"
          >{{ !isLoading ? 'Zuordnen' : 'Wird geladen...' }}
        </n-button>
      </div>
    </template>
    <n-simple-loader v-else-if="isLoading" class="mt-12 ml-auto mr-auto"></n-simple-loader>
  </div>
  <n-dialog
    v-model:is-open="isDeleteTransferDialogOpen"
    subject="Übertragung widerrufen"
    description="Durch das Löschen dieser Übertragung werden alle übertragenen Kursfortschritte rückgängig gemacht"
  >
    <template #image>
      <img :src="ASSET_URLS.trashcan" height="160" width="160" alt="" aria-hidden="true" />
    </template>
    <template #actions>
      <n-button @click="cancelDeleteTransfer">Abbrechen</n-button>
      <n-button variant="primary" @click="deleteTransfer"> Klar </n-button>
    </template>
  </n-dialog>
</template>

<script setup>
import { NButton, NDialog, NIcon, NSelect, NSimpleLoader, NTable } from '@careerpartner/nitro';
import { computed, onBeforeMount, onBeforeUnmount } from 'vue';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import TransferTableRow from '../TransferTableRow.vue';
import {
  cancelDeleteTransfer,
  columnConfig,
  deleteTransfer,
  DO_NOT_TRANSFER_OPTION,
  errors,
  existingTransfers,
  initiateDeleteTransfer,
  isDeleteTransferDialogOpen,
  isLoading,
  populateTransferOptions,
  removeCourseToTransfer,
  selectCourseToTransfer,
  selectedTransfers,
  sourceStudyProgress,
  transferOptions,
  transferOptionsFiltered,
} from './index';

import { ASSET_URLS } from '@/assets/asset-urls';
import { getCourses } from '@/common/utils/getCourses';
import { StudyProgressAPI } from '@/services/study-progress-api';

const route = useRoute();
const store = useStore();

onBeforeMount(async () => {
  if (!store.getters['studyProgresses/hasStudyProgresses']) {
    await store.dispatch('studyProgresses/fetchStudyProgresses', store.getters['app/getAcademyId']);
  }
  await store.dispatch('studyProgresses/setSelectedStudyProgress', route.params.bookingId);
});

onBeforeUnmount(() => {
  reset();
});

const reset = () => {
  sourceStudyProgress.value = null;
  isLoading.value = false;
  transferOptions.value = [];
  selectedTransfers.value = [];
  errors.value = [];
  existingTransfers.value = [];
};

const studyProgressesMapped = computed(() =>
  store.getters['studyProgresses/getAllStudyProgressesWithoutSelected'].map((item) => {
    return {
      id: item.id,
      title: `${item.program.programCode} ${item.curriculumVersion.curriculumVersionCode}`,
    };
  })
);

const coursesFromSourceCurriculum = computed(() => {
  if (sourceStudyProgress.value && sourceStudyProgress.value.studyPlan) {
    return getCourses(sourceStudyProgress.value.studyPlan.curriculumTerms, true);
  }
  return [];
});

async function getStudyProgressCourses(studyProgressId) {
  if (!studyProgressId) return;
  isLoading.value = true;
  sourceStudyProgress.value = null;

  try {
    const { data } = await StudyProgressAPI.getStudyProgress(studyProgressId);
    await populateTransferOptions(store.getters['studyProgresses/getSelectedStudyProgress']);
    sourceStudyProgress.value = data.data;
  } catch (e) {
    const message =
      e.response.status === 404
        ? 'Der Studienfortschritt konnte nicht gefunden werden.'
        : 'Etwas ist schiefgelaufen. Bitte versuchen es erneut.';

    store.dispatch(`app/setError`, {
      message,
    });
    sourceStudyProgress.value = null;
    transferOptions.value = [];
  }
  isLoading.value = false;
}

async function transferCourses() {
  isLoading.value = true;
  const targetStudyProgressId = store.getters['studyProgresses/getSelectedStudyProgress']?.id;
  const result = selectedTransfers.value
    .filter((transfer) => transfer.course.id !== DO_NOT_TRANSFER_OPTION.id)
    .map((transfer) =>
      StudyProgressAPI.createTransfer({
        sourceStudyProgressId: sourceStudyProgress.value.id,
        sourceCurriculumCourseId: transfer.oldCourse.id,
        targetStudyProgressId,
        targetCurriculumCourseId: transfer.course.id,
      })
    );

  const results = await Promise.allSettled(result);
  let successfullTransfersCount = 0;
  results.forEach((result) => {
    if (result.status === 'rejected') {
      try {
        const transfer = JSON.parse(result.reason.config.data);
        errors.value.push({
          message: 'Kurs konnte nicht transferiert werden.',
          courseId: transfer.targetCurriculumCourseId,
        });
      } catch (e) {
        store.dispatch('app/setError', {
          message: `Etwas ist schiefgelaufen. Bitte versuchen es erneut.`,
        });
      }
    } else {
      successfullTransfersCount++;
    }
  });
  if (errors.value.length > 0) {
    store.dispatch('app/setError', {
      message: `${errors.value.length} Kurs(e) konnten nicht transferiert werden.`,
    });
  }
  if (successfullTransfersCount > 0) {
    store.commit(
      'app/setSnackbarMessage',
      `${successfullTransfersCount} Kurs(e) wurden erfolgreich transferiert.`
    );
  }

  await populateTransferOptions(store.getters['studyProgresses/getSelectedStudyProgress']);
  isLoading.value = false;
}
</script>
