export default () => ({
  searchTerm: null,
  enrolmentModal: {
    open: false,
    failedEnrolments: [],
  },
  unenrolmentModal: {
    open: false,
    failedUnenrolments: [],
  },
  selectedCurriculumModule: null,
  selectedCurriculumCourse: null,
  programPanelOpen: false,
  isRecognitionModeActive: false,
  selectedCoursesRecognition: {},
  successfulRecognitions: [],
});
