<template>
  <n-tooltip placement="top" :content="getStatusText">
    <div :class="`${getChipColor} flex w-7 h-7 rounded-sm test`">
      <n-icon :icon="getStatusIcon" class="m-auto text-white" />
    </div>
  </n-tooltip>
</template>
<script>
import { COURSE_STATUS } from '@/components/common/course-status/course-status.js';
import { NTooltip, NIcon } from '@careerpartner/nitro';

export default {
  name: 'CourseStatus',
  components: { NTooltip, NIcon },
  props: {
    status: {
      type: String,
      required: true,
    },
  },
  computed: {
    getStatusText() {
      switch (this.status) {
        case COURSE_STATUS.ENROLED:
          return 'In Kurs eingebucht';
        case COURSE_STATUS.EXAM_ADMISSION:
          return 'Prüfungszulassung erhalten';
        case COURSE_STATUS.WAITING_FOR_RESULT:
          return 'Ergebnis ausstehend';
        case COURSE_STATUS.PASSED:
          return 'Bestanden';
        case COURSE_STATUS.FAILED:
          return 'Nicht bestanden';
        default:
          return '';
      }
    },
    getStatusIcon() {
      switch (this.status) {
        case COURSE_STATUS.ENROLED:
          return 'mdi-bank-outline';
        case COURSE_STATUS.EXAM_ADMISSION:
          return 'mdi-thumb-up-outline';
        case COURSE_STATUS.WAITING_FOR_RESULT:
          return 'mdi-timer-sand';
        case COURSE_STATUS.PASSED:
          return 'mdi-check';
        case COURSE_STATUS.FAILED:
          return 'mdi-close';
        default:
          return '';
      }
    },
    getChipColor() {
      switch (this.status) {
        case COURSE_STATUS.ENROLED:
          return 'bg-gray-600';
        case COURSE_STATUS.EXAM_ADMISSION:
          return 'bg-status-pink';
        case COURSE_STATUS.WAITING_FOR_RESULT:
          return 'bg-status-yellow';
        case COURSE_STATUS.PASSED:
          return 'bg-success';
        case COURSE_STATUS.FAILED:
          return 'bg-status-red';
        default:
          return '';
      }
    },
  },
};
</script>
