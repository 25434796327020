import app from '@/store/modules/app';
import enrolments from '@/store/modules/enrolments';
import offers from '@/store/modules/offers';
import overview from '@/store/modules/overview';
import recognitions from '@/store/modules/recognitions';
import studyProgresses from '@/store/modules/study-progresses';
import { createStore } from 'vuex';

export const store = createStore({
  modules: {
    app,
    studyProgresses,
    overview,
    offers,
    enrolments,
    recognitions,
  },
});
