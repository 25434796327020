export default {
  getSearchTerm: (state) => state?.searchTerm || '',

  getProgramsPanelOpen: (state) => state.programPanelOpen || false,

  isEnrolmentOpen: (state) => {
    return state.enrolmentModal.open;
  },

  isUnenrolmentOpen: (state) => {
    return state.unenrolmentModal.open;
  },

  selectedCurriculumModule: (state) => {
    return state.selectedCurriculumModule;
  },

  selectedCurriculumCourse: (state) => {
    return state.selectedCurriculumCourse;
  },

  getFailedEnrolments: (state) => {
    return state.enrolmentModal.failedEnrolments;
  },

  getFailedUnenrolments: (state) => {
    return state.unenrolmentModal.failedUnenrolments;
  },

  isCourseSelectedForRecognition: (state) => (curriculumCourseId) =>
    !!state.selectedCoursesRecognition[curriculumCourseId],
};
