export default {
  hasErrors(state) {
    return Boolean(state.errors?.length);
  },
  currentError(state) {
    return state.errors?.length > 0 ? state.errors[0] : undefined;
  },
  snackbar(state) {
    return state.snackbar;
  },
  getProfile(state) {
    return state.profile || {};
  },
  getAcademyId(state) {
    return state.profile?.academyId;
  },
  getDamFolderUrl(state) {
    return state.profile?.damFolderUrl || '';
  },
};
