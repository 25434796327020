<template>
  <div class="grid grid-cols-12 auto-rows-auto gap-4">
    <div class="col-span-6 col-start-4 mt-14 justify-self-center">
      <div class="flex flex-col items-center text-center">
        <img :src="notFoundImg" alt="" />
        <h1 class="mt-4 uppercase text-blue-500 font-bold text-4xl">
          Studienfortschritt konnte nicht gefunden werden.
        </h1>
        <div class="mt-4 text-gray-700 text-base">
          <p>
            Falls der Studienfortschritt in Care existiert, aber in EPOS nicht gefunden werden
            konnte, wenden sie sich bitte an das IT Support team.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import pageTitleMixin from '@/common/mixins/page-title';

export default {
  name: 'NotFound',
  mixins: [pageTitleMixin],
  data: () => ({
    pageTitle: 'Studienfortschritt noch nicht vorhanden',
    notFoundImg:
      'https://res.cloudinary.com/iubh/image/upload/v1659353962/IT%20-%20Epos%20/illustrations/epos-study-progress-not-found.svg',
  }),
};
</script>
