<template>
  <div class="font-bold whitespace-nowrap">
    <span
      class="h-3 w-3 inline-block rounded-full mr-2"
      :class="studyProgressStatusColor.bg"
      data-test="study-progress__status-indicator"
    ></span>
    <span :class="studyProgressStatusColor.text" data-test="study-progress__status-text">
      {{ studyProgressStatus }}
    </span>
  </div>
</template>

<script>
import { STUDY_PROGRESS_STATUS } from '@/components/common/study-progress/studyProgressStates';

export default {
  name: 'StudyProgressStatus',
  props: {
    status: {
      type: String,
      default: '',
    },
  },
  computed: {
    studyProgressStatus() {
      switch (this.status) {
        case STUDY_PROGRESS_STATUS.ACTIVE:
          return 'Aktiv';
        case STUDY_PROGRESS_STATUS.ACTIVE_WITH_RESTRICTION:
          return 'Eingeschränkt aktiv';
        case STUDY_PROGRESS_STATUS.ON_HOLD:
          return 'Pausiert';
        case STUDY_PROGRESS_STATUS.COMPLETED:
          return 'Abgeschlossen';
        case STUDY_PROGRESS_STATUS.CANCELLED:
          return 'Abgebrochen';
        case STUDY_PROGRESS_STATUS.CLOSED:
          return 'Beendet';
        case STUDY_PROGRESS_STATUS.FAILED:
          return 'Nicht Bestanden';
        default:
          return 'N/A';
      }
    },
    studyProgressStatusColor() {
      switch (this.status) {
        case STUDY_PROGRESS_STATUS.ACTIVE:
        case STUDY_PROGRESS_STATUS.ACTIVE_WITH_RESTRICTION:
        case STUDY_PROGRESS_STATUS.ON_HOLD:
          return {
            text: 'text-green-600',
            bg: 'bg-green-600',
          };
        case STUDY_PROGRESS_STATUS.COMPLETED:
          return {
            text: 'text-status-blue',
            bg: 'bg-status-blue',
          };
        case STUDY_PROGRESS_STATUS.CANCELLED:
        case STUDY_PROGRESS_STATUS.CLOSED:
        case STUDY_PROGRESS_STATUS.FAILED:
          return {
            text: 'text-status-pink',
            bg: 'bg-status-pink',
          };
        default:
          return {
            text: 'text-status-gray',
            bg: 'bg-status-gray',
          };
      }
    },
  },
};
</script>
