export default {
  resetOverview(state) {
    state.searchTerm = null;
    state.programPanelOpen = false;
  },

  toggleProgramPanelOpen(state) {
    state.programPanelOpen = !state.programPanelOpen;
  },

  /* search */
  setSearchTerm(state, searchTerm) {
    state.searchTerm = searchTerm;
  },
  resetSearchTerm(state) {
    state.searchTerm = null;
  },

  /* enrolments */
  closeAllModals(state) {
    state.enrolmentModal = {
      open: false,
      failedEnrolments: [],
    };
    state.unenrolmentModal = {
      open: false,
      failedUnenrolments: [],
    };
    state.selectedCurriculumModule = null;
    state.selectedCurriculumCourse = null;
  },

  openEnrolment(state, { curriculumModule }) {
    state.selectedCurriculumModule = curriculumModule;
    state.enrolmentModal = {
      failedEnrolments: [],
      open: true,
    };
  },
  setFailedEnrolments(state, value) {
    state.enrolmentModal = {
      ...state.enrolmentModal,
      failedEnrolments: value,
    };
  },

  openUnenrolment(state, { curriculumModule, curriculumCourse }) {
    state.selectedCurriculumModule = curriculumModule;
    state.selectedCurriculumCourse = curriculumCourse;
    state.unenrolmentModal = {
      failedUnenrolments: [],
      open: true,
    };
  },
  setFailedUnenrolments(state, value) {
    state.unenrolmentModal = {
      ...state.unenrolmentModal,
      failedUnenrolments: value,
    };
  },

  /* recognitions */
  setRecognitionMode(state, enabled) {
    state.isRecognitionModeActive = enabled;
  },

  addCourseRecognitionOptions(state, curriculumCourseId) {
    state.selectedCoursesRecognition[curriculumCourseId] = {
      recognitionGradeState: null,
      link: '',
      error: undefined,
    };
  },

  removeCourseRecognitionOptions(state, curriculumCourseId) {
    delete state.selectedCoursesRecognition[curriculumCourseId];
  },

  removeAllCourseRecognitionOptions(state) {
    state.selectedCoursesRecognition = {};
  },

  setErrorForCourseRecognition(state, { curriculumCourseId, error }) {
    state.selectedCoursesRecognition[curriculumCourseId].error = error;
  },

  addSuccessfulCourseRecognition(state, course) {
    state.successfulRecognitions.push(course);
  },

  removeAllSuccessfulCourseRecognitions(state) {
    state.successfulRecognitions = [];
  },

  setCourseRecognitionGradeState(state, options) {
    state.selectedCoursesRecognition[options.curriculumCourseId]['recognitionGradeState'] =
      options.recognitionGradeState;
  },

  setCourseRecognitionLink(state, options) {
    state.selectedCoursesRecognition[options.curriculumCourseId]['link'] = options.link;
  },
};
