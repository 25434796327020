<template>
  <slot :active="active" :toggle="toggle" />
</template>
<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'Toggle',
  data: () => ({
    active: true,
  }),
  methods: {
    toggle() {
      this.active = !this.active;
    },
  },
};
</script>
