<template>
  <template v-if="active">
    <grades-table-curriculum-structure-element-head
      :active="active"
      :name="curriculumStructureElement.title"
    />
    <grades-table-curriculum-module
      v-for="curriculumModule of curriculumStructureElement.curriculumModules"
      :key="`curriculum-module-cse-child-${curriculumModule.id}`"
      :active="active"
      :curriculum-module="curriculumModule"
    />
  </template>
</template>
<script>
import GradesTableCurriculumStructureElementHead from '@/components/overview/GradesTableCurriculumStructureElementHead.vue';
import GradesTableCurriculumModule from '@/components/overview/GradesTableCurriculumModule.vue';

export default {
  name: 'GradesTableCurriculumStructureElement',
  components: {
    GradesTableCurriculumModule,
    GradesTableCurriculumStructureElementHead,
  },
  props: {
    active: {
      type: Boolean,
      required: true,
    },
    curriculumStructureElement: {
      type: Object,
      default: () => {},
    },
  },
};
</script>
