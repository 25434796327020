<template>
  <n-banner
    v-if="hasErrors"
    variant="error"
    style="top: 50px"
    class="sticky z-90"
    data-test="grades-common__error-banner"
    :actions="actions"
    @click:reload-page="reloadPage"
    @close="dismissError"
  >
    <template #message>
      {{ currentError.message }}
    </template>
  </n-banner>
</template>

<script>
import { NBanner } from '@careerpartner/nitro';
import { mapActions, mapGetters } from 'vuex';
export default {
  name: 'ErrorBanner',
  components: {
    NBanner,
  },
  data() {
    return {
      actions: [{ name: 'reload-page', text: 'Neu Laden' }],
    };
  },
  computed: {
    ...mapGetters('app', {
      hasErrors: 'hasErrors',
      currentError: 'currentError',
    }),
  },
  methods: {
    ...mapActions({
      dismissError: 'app/dismissError',
    }),
    reloadPage() {
      location.reload();
    },
  },
};
</script>
