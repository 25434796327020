<template>
  <n-modal
    :is-open="true"
    auto-height
    title="Ausbuchen"
    description="Modal zum Ausbuchen von Modul und Kursen"
    @close="close"
  >
    <div class="text-center">
      <div class="flex justify-center mb-6">
        <div
          class="w-16 h-16 bg-contain bg-no-repeat bg-center"
          :style="`background-image: url('${deleteIcon}');width:56px;height:70px;`"
        ></div>
      </div>
      <h2 class="text-xl font-semibold text-center mb-4">
        Bist Du sicher, dass Du die Einbuchung löschen möchtest?
      </h2>
      <div
        :class="failedUnenrolments.length ? 'mb-4' : 'mb-12'"
        class="flex justify-center gap-5 items-center text-center"
      >
        <span>{{ name }}</span>
        <module-chip v-if="isCurriculumModule" />
        <course-chip v-else />
      </div>
    </div>
    <n-banner
      v-if="failedUnenrolments.length"
      variant="error"
      icon="bug-outline"
      data-test="enrolment-modal__error-banner"
      class="h-full items-start mb-4"
    >
      <template #header>
        {{ failedUnenrolments[0].message }}
      </template>
      <template #message>
        <div class="text-left py-2">
          <span class="flex">
            {{ failedUnenrolments[0].description }}
          </span>
        </div>
      </template>
      <template #actions>
        <n-button variant="quaternary" @click="failedUnenrolments = []">
          <span class="text-white hover:text-gray-100 uppercase">
            <n-icon icon="mdi-close"></n-icon>
          </span>
        </n-button>
      </template>
    </n-banner>
    <template #actions>
      <n-button :disabled="loading" variant="secondary" @click="close">Abbrechen</n-button>
      <n-button :disabled="loading" variant="primary" class="danger" @click="deleteEnrolment">{{
        loading ? 'Wird gelöscht...' : 'Löschen'
      }}</n-button>
    </template>
  </n-modal>
</template>

<script>
import { ASSET_URLS } from '@/assets/asset-urls';
import { extractErrorDescription, mapErrorMessage } from '@/common/errors';
import { NBanner, NButton, NIcon, NModal } from '@careerpartner/nitro';
import CourseChip from '@/components/common/CourseChip.vue';
import ModuleChip from '@/components/common/ModuleChip.vue';
import { mapActions, mapGetters, mapMutations } from 'vuex';

export default {
  name: 'SingleUnenrolment',
  components: {
    CourseChip,
    ModuleChip,
    NButton,
    NBanner,
    NIcon,
    NModal,
  },

  props: {
    curriculumModule: {
      type: Object,
      default: () => {},
    },
    curriculumCourse: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      confirmed: false,
      loading: false,
      offer: null,
      deleteIcon: ASSET_URLS.trashcan,
    };
  },

  computed: {
    ...mapGetters({
      getSelectedStudyProgress: `studyProgresses/getSelectedStudyProgress`,
      getFailedUnenrolments: 'overview/getFailedUnenrolments',
    }),

    isCurriculumModule() {
      return !this.curriculumCourse && !!this.curriculumModule;
    },
    id() {
      return this.isCurriculumModule ? this.curriculumModule.id : this.curriculumCourse.id;
    },
    enrolment() {
      return this.isCurriculumModule
        ? this.curriculumModule.moduleProgress?.moduleEnrolment
        : this.curriculumCourse.courseProgress?.courseEnrolment;
    },
    offerId() {
      return this.isCurriculumModule
        ? this.curriculumModule.moduleProgress?.moduleEnrolment?.moduleOfferId
        : this.curriculumCourse.courseProgress?.courseEnrolment?.courseOfferId;
    },
    failedUnenrolments: {
      get() {
        return this.getFailedUnenrolments;
      },
      set(value) {
        this.setFailedUnenrolments(value);
      },
    },
    name() {
      if (this.offer?.name) return this.offer?.name;
      else {
        const type = this.isCurriculumModule ? 'Modulangebot' : 'Vorlesungsreihe';

        return `${type} Unbekannt`;
      }
    },
  },

  async mounted() {
    let offers;
    if (this.isCurriculumModule) {
      offers = await this.fetchModuleOffers({
        curriculumModuleId: this.curriculumModule.id,
        moduleMainVersionId: this.curriculumModule.moduleMainVersionId,
      });
    } else {
      offers = await this.fetchCourseOffers({
        curriculumCourseId: this.curriculumCourse.id,
        courseMainVersionId: this.curriculumCourse.courseMainVersionId,
      });
    }

    this.offer = offers?.find((offer) => offer.id === this.offerId);
  },

  methods: {
    ...mapMutations({
      setSnackbarMessage: 'app/setSnackbarMessage',
      resetOffers: 'offers/reset',
      setFailedUnenrolments: 'overview/setFailedUnenrolments',
      closeAllModals: 'overview/closeAllModals',
    }),

    ...mapActions({
      fetchModuleOffers: 'offers/fetchModuleOffers',
      fetchCourseOffers: 'offers/fetchCourseOffers',
      deleteModuleEnrolment: 'enrolments/deleteModuleEnrolment',
      deleteCourseEnrolment: 'enrolments/deleteCourseEnrolment',
      fetchDataForSelectedStudyProgress: 'studyProgresses/fetchDataForSelectedStudyProgress',
    }),

    close() {
      this.resetOffers();
      this.closeAllModals();
    },

    async onSuccessfulUnenrolment() {
      await this.fetchDataForSelectedStudyProgress(this.getSelectedStudyProgress.id);
      this.close();
      this.setSnackbarMessage('Einbuchung wurde erfolgreich gelöscht.');
    },

    async deleteEnrolment() {
      try {
        this.loading = true;
        if (this.isCurriculumModule) {
          await this.deleteModuleEnrolment(this.enrolment.id);
        } else {
          await this.deleteCourseEnrolment(this.enrolment.id);
        }
        await this.onSuccessfulUnenrolment();
      } catch (error) {
        if (error.response && error.response.status === 404) {
          await this.onSuccessfulUnenrolment();
        }

        this.failedUnenrolments = [
          {
            ...this.enrolment,
            name: this.offer?.name,
            message: mapErrorMessage(error, 'Buchung'),
            description: extractErrorDescription(error),
          },
        ];
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="css" scoped>
.single-unenrolment-modal .modal-container {
  width: 350px !important;
}
</style>
