<template>
  <!-- This component is used outside of the e-applet-wrapper, so we cannot use tailwind classes -->
  <splitpanes
    horizontal
    style="height: 100vh; max-height: 100vh"
    :style="{
      height: isRecognitionModeActive ? 'calc(100vh - 15px)' : '100vh',
    }"
  >
    <pane class="px-4 overflow-y-scroll w-full pb-24">
      <slot name="splitViewContent"></slot>
    </pane>
    <pane
      v-if="isRecognitionModeActive"
      class="w-full bg-blue overflow-hidden"
      max-size="80"
      min-size="20"
      size="40"
    >
      <recognition-menu />
    </pane>
  </splitpanes>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import { Splitpanes, Pane } from 'splitpanes';
import RecognitionMenu from '@/components/overview/recognition/RecognitionMenu.vue';
import 'splitpanes/dist/splitpanes.css';

export default {
  name: 'RecognitionSplitView',
  components: { Splitpanes, Pane, RecognitionMenu },
  computed: {
    ...mapState('overview', ['isRecognitionModeActive']),
  },
  unmounted() {
    this.disableRecognitionMode();
  },
  methods: {
    ...mapActions('overview', ['disableRecognitionMode']),
  },
};
</script>
<style>
.splitpanes__splitter {
  height: 15px;
  cursor: row-resize;
  position: relative;
  @apply bg-blue-600 w-full;
}
.splitpanes__splitter::after {
  content: '';
  height: 15px;
  background-image: url('../../../assets/images/splitpane-splitter-icon.svg');
  @apply absolute w-full bg-center bg-no-repeat;
}
.hide-scrollbar::-webkit-scrollbar {
  @apply hidden;
}
</style>
