import { StudyProgressAPI } from '@/services/study-progress-api';

export default {
  async fetchModuleOffers({ commit, dispatch }, { curriculumModuleId, moduleMainVersionId }) {
    try {
      const { data } = await StudyProgressAPI.getModuleOffersForModule(
        curriculumModuleId,
        moduleMainVersionId
      );
      commit(
        'saveModuleOffers',
        data.map((offer) => ({ ...offer, curriculumModuleId }))
      );
      return data;
    } catch (error) {
      console.error(error);
      dispatch('app/setError', error, { root: true });
    }
  },

  async fetchCourseOffers({ commit, dispatch }, { curriculumCourseId, courseMainVersionId }) {
    try {
      const { data } = await StudyProgressAPI.getCourseOffersForCourse(
        curriculumCourseId,
        courseMainVersionId
      );
      commit(
        'saveCourseOffers',
        data.map((offer) => ({
          ...offer,
          curriculumCourseId,
        }))
      );
      return data;
    } catch (error) {
      console.error(error);
      dispatch('app/setError', error, { root: true });
    }
  },
};
