<template>
  <div
    v-if="getAllStudyProgressesWithoutSelected.length && !isRecognitionModeActive"
    data-test="grades-overview__programs-panel"
  >
    <n-button
      variant="quaternary"
      class="my-1 whitespace-nowrap"
      small
      @click="toggleProgramPanelOpen"
    >
      <n-icon :icon="getProgramsPanelOpen ? 'mdi-chevron-up' : 'mdi-chevron-down'" />
      Weitere Programme anzeigen ({{ getAllStudyProgressesWithoutSelected.length }})
    </n-button>
    <div
      v-if="getProgramsPanelOpen"
      class="grid gap-6 grid-flow-row-dense xl:grid-cols-4 lg:grid-cols-3 grid-cols-2"
      style="min-width: 28rem"
      data-test="grades-overview__programs-panel--content"
    >
      <program-card
        v-for="studyProgress in getAllStudyProgressesWithoutSelected"
        :key="studyProgress.id"
        :study-progress="studyProgress"
        @click="$emit('card-click')"
      ></program-card>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapState } from 'vuex';
import { NIcon, NButton } from '@careerpartner/nitro';
import ProgramCard from '@/components/overview/ProgramCard.vue';

export default {
  name: 'ProgramsPanel',
  components: {
    NIcon,
    NButton,
    ProgramCard,
  },
  emits: ['card-click'],
  computed: {
    ...mapGetters({
      getProgramsPanelOpen: 'overview/getProgramsPanelOpen',
      getAllStudyProgressesWithoutSelected: 'studyProgresses/getAllStudyProgressesWithoutSelected',
    }),
    ...mapState('overview', ['isRecognitionModeActive']),
  },
  methods: {
    ...mapMutations({
      toggleProgramPanelOpen: `overview/toggleProgramPanelOpen`,
    }),
  },
};
</script>
