import { getCourses } from '@/common/utils/getCourses';
import { StudyProgressAPI } from '@/services/study-progress-api';
import type { CurriculumCourse } from '@/types/CurriculumCourse';
import { computed, ref, watch } from 'vue';
import type { StudyProgress } from '@/types/StudyProgress';
import { store } from '@/store';

type SelectedTransfer = {
  course: CurriculumCourse;
  oldCourse: CurriculumCourse;
};

export type TransferError = {
  message: string;
  courseId: string;
};

export const DO_NOT_TRANSFER_OPTION: CurriculumCourse = Object.freeze({
  id: '-1',
  courseTitle: 'Wird nicht transferiert',
  courseProgress: null,
  ects: 0,
  curriculumCourseCode: '',
  careCurriculumCourseId: '',
  startTerm: '',
  startYear: 2000,
});
export const isLoading = ref(false);
export const transferOptions = ref<CurriculumCourse[]>([]);
export const existingTransfers = ref<CurriculumCourse[]>([]);
export const selectedTransfers = ref<SelectedTransfer[]>([]);
export const sourceStudyProgress = ref<StudyProgress | null>(null);
export const errors = ref<TransferError[]>([]);
export const isDeleteTransferDialogOpen = ref(false);
export const transferToDelete = ref<CurriculumCourse | null>(null);
export const columnConfig = [
  { label: 'Kurs aus altem Programm', key: 'courseTitle', class: 'w-28' },
  { label: 'Kurscode', key: 'curriculumCourseCode', class: 'w-10' },
  { label: 'ECTS-Punkte', key: 'ects', class: 'w-8' },
  {
    label: 'Kurs aus neuem Programm',
    key: 'newCourseCode',
    class: 'w-32',
  },
];

export const transferredEcts = computed(() => {
  return selectedTransfers.value.reduce((acc, transfer) => acc + transfer.course.ects, 0);
});

export const transferOptionsFiltered = computed(() => {
  const courses = transferOptions.value
    .filter(
      (course) => !selectedTransfers.value.map((transfer) => transfer.course.id).includes(course.id)
    )
    .sort((a, b) => a.courseTitle.localeCompare(b.courseTitle));
  courses.unshift(DO_NOT_TRANSFER_OPTION);
  return courses;
});

function validateTransfer(source: CurriculumCourse, target: CurriculumCourse) {
  if (source.ects !== target.ects) {
    errors.value.push({
      message:
        'Transfer nicht möglich, da der Kurscode oder die ECTS nicht übereinstimmen. Verwenden Sie für diesen Kurs bitte die Anerkennungsfunktion.',
      courseId: source.id,
    });
    return false;
  }
  if (source.curriculumCourseCode !== target.curriculumCourseCode) {
    errors.value.push({
      message: 'Transfer nicht möglich, da die Kurscodes nicht übereinstimmen.',
      courseId: source.id,
    });
    return false;
  }

  return true;
}

export const selectCourseToTransfer = (course: CurriculumCourse, item: CurriculumCourse) => {
  if (course.id === DO_NOT_TRANSFER_OPTION.id) {
    return;
  }

  if (validateTransfer(course, item)) {
    selectedTransfers.value.push({ course, oldCourse: item });
  }
};
export const removeCourseToTransfer = (course: CurriculumCourse) => {
  selectedTransfers.value = selectedTransfers.value.filter(
    (transfer) => transfer.course.id !== course.id
  );
};

export const cancelDeleteTransfer = () => {
  isDeleteTransferDialogOpen.value = false;
  transferToDelete.value = null;
};

export const initiateDeleteTransfer = (transfer: CurriculumCourse) => {
  transferToDelete.value = transfer;
  isDeleteTransferDialogOpen.value = true;
};

export const deleteTransfer = async () => {
  let result = undefined;

  if (transferToDelete.value !== null) {
    if (transferToDelete.value.courseProgress?.transferredRecognition?.id) {
      result = await StudyProgressAPI.deleteTransferredRecognition(
        transferToDelete.value.courseProgress.transferredRecognition.id
      );
    } else {
      result = await StudyProgressAPI.deleteTransfer(
        transferToDelete.value.courseProgress?.courseEnrolment?.id
      );
    }
  }

  if (result.status === 200) {
    store.commit(
      'app/setSnackbarMessage',
      'Das transferierte Ergebnis wurde erfolgreich widerrufen.'
    );
  }

  transferToDelete.value = null;
  isDeleteTransferDialogOpen.value = false;
  if (sourceStudyProgress.value !== null) {
    await populateTransferOptions(store.getters['studyProgresses/getSelectedStudyProgress']);
  }
};

// Resets errors for courses that have transfer target changed and therefore the error is no longer relevant
watch(
  () => selectedTransfers.value,
  (newTransfers) => {
    const courseIds = newTransfers.map((transfer) => transfer.course.id);
    errors.value = errors.value.filter((error) => !courseIds.includes(error.courseId));
  },
  { deep: true }
);

export const populateTransferOptions = async (studyProgress: StudyProgress) => {
  const courses = getCourses(studyProgress.studyPlan.curriculumTerms);
  transferOptions.value = courses
    .filter((course) => course.courseProgress === null)
    .map((course) => ({
      ...course,
      courseTitle: `${course.courseTitle} (${course.curriculumCourseCode}) - ${course.ects} ECTS-Punkte`,
    }));
  existingTransfers.value = courses.filter(
    (course) => course.courseProgress !== null && course.courseProgress.transferred
  );
};
