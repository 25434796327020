<template>
  <span v-if="grade && isGraded" :class="classes">
    {{ formatGrade(grade) }}
  </span>
  <span v-else-if="grade && !isGraded" :class="classes">
    {{ isPassed ? 'Best.' : 'N. Best.' }}
  </span>
</template>

<script>
import { formatGrade } from '@/common/utils/format';
import { MODULE_STATUS } from '@/components/common/module-status/module-status';

export default {
  name: 'ModuleGrade',
  props: {
    classes: {
      type: String,
      required: false,
      default: '',
    },
    status: {
      type: String,
      required: true,
    },
    isGraded: {
      type: Boolean,
      required: true,
    },
    grade: {
      type: Number,
      required: false,
      default: null,
    },
  },
  computed: {
    isPassed() {
      return this.status === MODULE_STATUS.PASSED;
    },
  },
  methods: {
    formatGrade,
  },
};
</script>
