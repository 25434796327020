<template>
  <n-table-row :item="props.item" class="odd:bg-blue-100">
    <td>{{ props.item.courseTitle }}</td>
    <td>{{ props.item.curriculumCourseCode }}</td>
    <td>{{ props.item.ects }}</td>
    <td>
      <template v-if="existingTransfer === undefined">
        <p v-if="options.length === 0">Keine Option verfügbar für diesen Kurs.</p>
        <n-select
          v-else
          id="transfer-select"
          searchable
          label="Auswahl von Kurs"
          :options="options"
          track-by="id"
          :errors="errors"
          option-label="courseTitle"
          :model-value="selectedCourse ? selectedCourse.id : undefined"
          @update:model-value="selectCourseToTransfer"
        >
        </n-select>
      </template>

      <div v-else class="flex items-center">
        {{ existingTransfer.courseTitle }} ({{ existingTransfer.curriculumCourseCode }})
        <n-tooltip :disabled="!isTransferDeleteDisabled" class="!ml-auto">
          <n-button
            variant="quarternary"
            class="mr-2"
            :disabled="isTransferDeleteDisabled"
            @click="deleteTransfer"
          >
            <n-icon
              icon="close"
              class="icon-2xl"
              :class="{
                'text-gray-700': isTransferDeleteDisabled,
                'text-red-500': !isTransferDeleteDisabled,
              }"
            ></n-icon>
          </n-button>
          <template #content
            >Diese Übertragung kann nicht gelöscht werden, da Sie in diesem Kurs Fortschritte
            gemacht haben.</template
          >
        </n-tooltip>
      </div>
    </td>
  </n-table-row>
</template>

<script setup lang="ts">
import type { CourseProgress } from '@/types/CourseProgress';
import type { CurriculumCourse } from '@/types/CurriculumCourse';
import { NButton, NIcon, NSelect, NTableRow, NTooltip } from '@careerpartner/nitro';
import { computed, ref, watch } from 'vue';
import { DO_NOT_TRANSFER_OPTION, type TransferError } from './TransferOverview';

const props = defineProps<{
  item: {
    courseTitle: string;
    curriculumCourseCode: string;
    ects: number;
    courseProgress: CourseProgress;
  };
  coursesToTransfer: CurriculumCourse[];
  transferErrors: TransferError[];
  existingTransfers: CurriculumCourse[];
}>();

const selectedCourse = ref();
const errors = ref<string[]>([]);
const isTransferDeleteDisabled = computed(
  () =>
    existingTransfer.value?.courseProgress?.examResults?.some(
      (result) => result.transferred === false
    )
);
watch(
  () => props.transferErrors,
  (newErrors) => {
    const error = newErrors.find((error) => error.courseId === selectedCourse.value?.id);
    if (error) {
      errors.value = [error.message];
    }
  },
  { deep: true }
);

const existingTransfer = computed(() => {
  return props.existingTransfers.find((transfer) => {
    if (transfer.courseProgress?.recognized) {
      return transfer.courseProgress.recognition?.id === props.item.courseProgress?.recognition?.id;
    }

    return (
      transfer.courseProgress?.courseEnrolment?.originalCourseEnrolmentId ===
      props.item.courseProgress.courseEnrolment?.id
    );
  });
});

const options = computed(() => {
  if (selectedCourse.value !== undefined && selectedCourse.value.id !== '-1') {
    return [selectedCourse.value, DO_NOT_TRANSFER_OPTION];
  }

  return props.coursesToTransfer.filter(
    (ct) => ct.curriculumCourseCode && ct.curriculumCourseCode === props.item.curriculumCourseCode
  );
});

const emit = defineEmits(['selectCourse', 'removeCourse', 'deleteTransfer']);

const deleteTransfer = () => {
  emit('deleteTransfer', existingTransfer.value);
};

const selectCourseToTransfer = (courseId: string | undefined) => {
  if (courseId === undefined) {
    removeSelectedCourse();
    return;
  }

  const newCourse = props.coursesToTransfer.find((course) => course.id === courseId);

  if (newCourse?.courseProgress !== null) {
    errors.value = ['Der ausgewählte Kurs hat bereits eine Zuordnung.'];
    setSelectedCourse(newCourse);
    return;
  }

  setSelectedCourse(newCourse);
  emit('selectCourse', selectedCourse.value);
  errors.value = [];
};

const removeSelectedCourse = () => {
  errors.value = [];

  if (selectedCourse.value !== undefined && selectedCourse.value.courseProgress !== null) {
    selectedCourse.value = undefined;
    return;
  }
  emit('removeCourse', selectedCourse.value);
  selectedCourse.value = undefined;
};

const setSelectedCourse = (newCourse: CurriculumCourse | undefined) => {
  if (selectedCourse.value !== undefined) {
    emit('removeCourse', selectedCourse.value);
  }

  selectedCourse.value = newCourse;
};
</script>
