import { COURSE_STATUS } from '@/components/common/course-status/course-status';
import { MODULE_STATUS } from '@/components/common/module-status/module-status';

export default {
  isCurriculumCourseUnenrolable: (_state, getters) => (curriculumCourse) => {
    // can not unenrol if course is not enroled
    if (!curriculumCourse?.courseProgress?.courseEnrolment) {
      return false;
    }

    return getters.isCurriculumCourseChildUnenrolable(curriculumCourse);
  },

  isCurriculumCourseChildUnenrolable: () => (curriculumCourse) => {
    // can not unenrol if course is recognized or has an exam result
    if (
      curriculumCourse?.courseProgress?.recognized ||
      curriculumCourse?.courseProgress?.examResult?.length
    ) {
      return false;
    }

    // can only unenrol as long as no status was achieved
    if (
      curriculumCourse?.courseProgress?.status &&
      curriculumCourse?.courseProgress?.status !== COURSE_STATUS.ENROLED &&
      curriculumCourse?.courseProgress?.status !== COURSE_STATUS.EXAM_ADMISSION
    ) {
      return false;
    }

    return true;
  },

  isCurriculumModuleUnenrolable: (_state, getters) => (curriculumModule) => {
    // can not unenrol if module is not enroled
    if (!curriculumModule?.moduleProgress?.moduleEnrolment) {
      return false;
    }

    // can only unenrol as long as no status was achieved
    if (curriculumModule?.moduleProgress?.status !== MODULE_STATUS.ENROLED) {
      return false;
    }

    // can only unenrol if all child courses are also unenrolable
    const unenrolableCurriculumCourse = curriculumModule.curriculumCourses
      .map((curriculumCourse) => getters.isCurriculumCourseChildUnenrolable(curriculumCourse))
      .find((isCourseUnenrolable) => isCourseUnenrolable === false);

    return unenrolableCurriculumCourse === undefined;
  },
};
