<template>
  <button
    :class="visible ? '' : 'translate-y-24'"
    class="transition-transform mt-4 transform h-12 w-12 bottom-12 right-6 rounded-md bg-cta hover:bg-cta-darker z-10"
    @click="scrollToTop"
  >
    <n-icon icon="arrow-collapse-up" class="text-white"></n-icon>
  </button>
</template>

<script>
import { NIcon } from '@careerpartner/nitro';

export default {
  name: 'ScrollToTopButton',
  components: {
    NIcon,
  },
  data() {
    return {
      visible: 0,
    };
  },
  mounted() {
    document.querySelector('.splitpanes__pane')?.addEventListener('scroll', this.handleScroll);
  },
  unmounted() {
    document.querySelector('.splitpanes__pane')?.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    handleScroll() {
      this.visible = document.querySelector('.splitpanes__pane').scrollTop > 600;
    },
    scrollToTop() {
      document.querySelector('.splitpanes__pane').scrollTo({ top: 0, behavior: 'smooth' });
    },
  },
};
</script>
