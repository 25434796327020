<template>
  <span v-if="grade && isGraded" :class="classes">
    {{ formatGrade(grade) }}
  </span>
  <span v-else-if="grade && !isGraded" :class="classes">Best.</span>
</template>

<script>
import { formatGrade } from '@/common/utils/format';

export default {
  name: 'RecognitionGrade',
  props: {
    classes: {
      type: String,
      required: false,
      default: '',
    },
    isGraded: {
      type: Boolean,
      required: true,
    },
    grade: {
      type: Number,
      required: false,
      default: null,
    },
  },
  methods: {
    formatGrade,
  },
};
</script>
