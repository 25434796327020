<template>
  <div>
    <grades-loader v-if="loading">{{ loading }}</grades-loader>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { NOT_FOUND, OVERVIEW } from '@/common/constants/router';
import GradesLoader from '@/components/common/Loader.vue';

export default {
  name: 'ProgressList',
  components: { GradesLoader },

  data() {
    return {
      studyProgresses: [],
      loading: null,
    };
  },

  computed: {
    ...mapGetters({
      profileAcademyId: `app/getAcademyId`,
      getFirstStudyProgress: `studyProgresses/getFirstStudyProgress`,
    }),
  },

  async created() {
    this.loading = 'Studienfortschritte werden geladen...';
    this.studyProgresses = await this.fetchStudyProgresses(this.profileAcademyId);

    if (!this.studyProgresses?.length) {
      await this.$router.push({
        name: NOT_FOUND,
      });
      return;
    }

    await this.$router.push({
      name: OVERVIEW,
      params: {
        id: this.$route.params.id,
        bookingId: this.getFirstStudyProgress.id,
      },
    });

    this.loading = null;
  },

  methods: {
    ...mapActions({
      fetchStudyProgresses: `studyProgresses/fetchStudyProgresses`,
    }),
  },
};
</script>
