/* istanbul ignore file */
export default {
  props: {
    profileName: {
      type: [String],
      // Allow `null` (this is the case before name is resolved & set)
      validator: (name) => ['string', null].includes(typeof name),
    },
  },
  metaInfo() {
    return {
      title: `${this.nameToPrepend}${this.pageTitle}`,
      titleTemplate: '%s | Kundenverwaltung | EPOS | IU',
    };
  },
  computed: {
    nameToPrepend() {
      return `${this.profileName ? this.profileName + ' - ' : ''}`;
    },
  },
};
