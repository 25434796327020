import { user } from '@epos/orchestrator';

const hasPermission = (resourcePath, action, context = 'study-progress') => {
  return user.permissions.some(
    (p) =>
      p.context === context &&
      p.resourcePath === resourcePath &&
      p.actions.some((a) => a === action)
  );
};

export const IamUser = {
  hasPermission,
};
