import { createApiClient } from '@epos/orchestrator/api';

// see https://careerpartner.atlassian.net/wiki/spaces/E/pages/3176956819/Student+Enrolment
const ApiClient = createApiClient({ basePath: '/student-enrolment/v1' });

// https://api-dev.iu.org/student-enrolment/v1/profiles
const getProfileById = (profileId) => ApiClient.instance.get(`/profiles/${profileId}`);

export const StudentEnrolmentApi = {
  getProfileById,
};
