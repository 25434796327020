export const UnenrolmentExceptionCodes = {
  GRADE_RESULT_EXISTS: 'GRADE.DOMAIN.RESULT_EXISTS',
  HAS_ENROLED_COURSES: 'ENROLMENT.DOMAIN.HAS_ENROLED_COURSES',
};

export const EnrolmentExceptionCodes = {
  COURSE_ALREADY_ENROLED: 'ENROLMENT.DOMAIN.COURSE_ALREADY_ENROLED',
  MODULE_ALREADY_ENROLED: 'ENROLMENT.DOMAIN.MODULE_ALREADY_ENROLED',
  NO_SUFFICIENT_ECTS_BUDGET: 'STUDY_PROGRESS.DOMAIN.NO_SUFFICIENT_ECTS_BUDGET',
  NOT_ENROLABLE: 'STUDY_PROGRESS.DOMAIN.NOT_ENROLABLE',
  CONTEXT_COURSE_NOT_PART_OF_PRODUCT_OFFER:
    'ENROLMENT.LINK.CONTEXT_COURSE_NOT_PART_OF_PRODUCT_OFFER',
  CONTEXT_MODULE_NOT_PART_OF_PRODUCT_OFFER:
    'ENROLMENT.LINK.CONTEXT_MODULE_NOT_PART_OF_PRODUCT_OFFER',
};

export const RecognitionExceptionCodes = {
  COURSE_ALREADY_RECOGNIZED: 'RECOGNITION.DOMAIN.COURSE_ALREADY_RECOGNIZED',
  INVALID_STUDYPROGRESS_STATUS: 'RECOGNITION.DOMAIN.INVALID_STUDYPROGRESS_STATUS_FOR_RECOGNITION',
  COURSE_ENROLLMENT_EXISTS: 'RECOGNITION.DOMAIN.COURSE_ENROLLMENT_EXISTS',
};

export const GeneralExceptionCodes = {
  NOT_FOUND: 'GENERIC.NOT_FOUND',
  UNKNOWN_EXCEPTION: 'GENERIC.UNKNOWN_EXCEPTION',
  VALIDATION_EXCEPTION: 'GENERIC.INPUT_VALIDATION_FAILED',
  ARGUMENT_INVALID: 'GENERIC.ARGUMENT_INVALID',
};
