<template>
  <div class="w-full h-full overflow-scroll flex flex-col min-h-max hide-scrollbar">
    <div
      class="h-20 flex items-center flex-shrink-0"
      style="border-bottom: 1px; border-style: solid; border-color: #8c9ac7"
    >
      <p class="font-bold ml-9">Anerkennung hinzufügen für “{{ getCurriculumVersionCode }}”</p>
    </div>
    <div
      v-for="successfulRecognition of successfulRecognitions"
      :key="successfulRecognition.id"
      class="px-8 pt-4"
    >
      <n-icon icon="mdi-check" size="l" class="text-success" />
      Der Kurs
      <span class="font-bold">{{ successfulRecognition.name }}</span> wurde erfolgreich anerkannt.
    </div>
    <div class="flex flex-grow">
      <div
        v-if="selectedCourseUuidsForRecognition < 1"
        class="flex w-full h-full justify-center items-center"
        data-test="study-progress__placeholder-no-courses-selected-for-recognition"
      >
        <div class="flex justify-center flex-col items-center w-full h-16">
          <div class="flex flex-grow justify-center items-center mb-3">
            <p class="font-bold text-base">Noch keine Kurse hinzugefügt</p>
          </div>
          <div class="flex flex-grow justify-center items-center">
            <p class="text-gray-700 text-base">
              Füge mehrere Kurse hinzu, um Leistungen schnell anzuerkennen.
            </p>
          </div>
        </div>
      </div>
      <div v-else class="w-full">
        <recognition-table class="px-8" />
      </div>
    </div>
    <div class="w-full h-24 flex justify-end items-center flex-shrink-0">
      <n-button variant="secondary" class="mr-5 h-9" @click="disableRecognitionMode"
        >Abbrechen
      </n-button>
      <n-button
        variant="primary"
        class="mr-5 h-9"
        :disabled="!allSelectedCourseOptionsAreValid || loading"
        @click="validateAndCreateRecognition()"
        >{{ loading ? 'Wird anerkannt...' : 'Anerkennen' }}
      </n-button>
    </div>
  </div>
</template>
<script>
import { NButton, NIcon } from '@careerpartner/nitro';
import RecognitionTable from '@/components/overview/recognition/RecognitionTable.vue';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { isValidRecognitionLink } from './helper';
import { mapErrorMessage } from '@/common/errors';

export default {
  name: 'RecognitionMenu',
  components: { NButton, NIcon, RecognitionTable },
  data() {
    return {
      loading: false,
    };
  },

  computed: {
    ...mapState('overview', ['selectedCoursesRecognition', 'successfulRecognitions']),
    ...mapGetters({
      getSelectedStudyProgress: `studyProgresses/getSelectedStudyProgress`,
      getCurriculumVersionCode: `studyProgresses/getCurriculumVersionCode`,
      findCurriculumCourseById: `studyProgresses/findCurriculumCourseById`,
    }),

    selectedCourseUuidsForRecognition() {
      return Object.keys(this.selectedCoursesRecognition);
    },

    allSelectedCourseOptionsAreValid() {
      const curriculumCourseIds = this.selectedCourseUuidsForRecognition;
      return (
        curriculumCourseIds.length > 0 &&
        curriculumCourseIds.every((curriculumCourseId) => {
          const selectedCurriculumCourse = this.selectedCoursesRecognition[curriculumCourseId];
          const hasRecognitionGradeState = !!selectedCurriculumCourse.recognitionGradeState;

          return isValidRecognitionLink(selectedCurriculumCourse.link) && hasRecognitionGradeState;
        })
      );
    },
  },

  methods: {
    ...mapActions({
      disableRecognitionMode: 'overview/disableRecognitionMode',
      createRecognition: 'overview/createRecognition',
      fetchDataForSelectedStudyProgress: 'studyProgresses/fetchDataForSelectedStudyProgress',
    }),

    ...mapMutations({
      setSnackbarMessage: 'app/setSnackbarMessage',
      addErrorMessage: 'app/pushToErrorStack',
      addSuccessfulCourseRecognition: 'overview/addSuccessfulCourseRecognition',
      removeRecognitionOptions: 'overview/removeCourseRecognitionOptions',
      setErrorForCourseRecognition: 'overview/setErrorForCourseRecognition',
      increaseEctsRecognized: 'studyProgresses/increaseEctsRecognized',
    }),

    async validateAndCreateRecognition() {
      this.loading = true;
      const requestData = [];
      const results = [];
      let hasRejections = false;

      for (const [curriculumCourseId, { recognitionGradeState, link }] of Object.entries(
        this.selectedCoursesRecognition
      )) {
        const isGraded = recognitionGradeState !== 'Bestanden';
        const grade = isGraded ? Number(recognitionGradeState.replace(',', '.')) : 1.0;
        requestData.push({
          studyProgressId: this.getSelectedStudyProgress?.id,
          curriculumCourseId,
          isGraded,
          grade,
          link,
        });
      }

      for (const request of requestData) {
        try {
          const result = await this.createRecognition(request);
          results.push(result);
          const curriculumCourse = this.findCurriculumCourseById(request.curriculumCourseId);
          this.addSuccessfulCourseRecognition(curriculumCourse);
          this.removeRecognitionOptions(curriculumCourse.externalCareId);
          this.increaseEctsRecognized(curriculumCourse.ects);
        } catch (e) {
          const error = mapErrorMessage(e);

          this.setErrorForCourseRecognition({
            curriculumCourseId: request.curriculumCourseId,
            error,
          });

          hasRejections = true;
        }
      }

      if (!hasRejections) {
        const message =
          results.length > 1
            ? `${results.length} Anerkennungen wurden hinzugefügt.`
            : `Anerkennung wurde hinzugefügt.`;
        this.setSnackbarMessage(message);
        this.disableRecognitionMode();
      }

      this.loading = false;
      this.fetchDataForSelectedStudyProgress(this.getSelectedStudyProgress.id);
    },
  },
};
</script>
<style scoped>
.hide-scrollbar::-webkit-scrollbar {
  @apply hidden;
}

.hide-scrollbar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
</style>
