<template>
  <div class="rounded relative h-9" style="min-width: 18rem">
    <div
      class="absolute w-full h-3 rounded bg-blue-200"
      data-test="study-progress__progress-ects-total"
    ></div>

    <div
      v-if="!isInitial"
      class="absolute h-3 rounded-l bg-stripes"
      :class="{
        'rounded-r': hasFinished,
      }"
      :style="{ width: widthEctsBooked + '%' }"
      data-test="study-progress__progress-ects-booked"
    ></div>

    <div
      v-if="!isInitial"
      class="absolute h-3 rounded-l bg-blue-600"
      :class="{
        'rounded-r': hasFinished,
      }"
      :style="{ width: widthEctsAchieved + '%' }"
      data-test="study-progress__progress-ects-achieved"
    ></div>

    <div
      v-if="!isInitial && !hasFinished"
      class="absolute h-8 border-r-2 r-1 box-border"
      :class="{
        'border-gray-700': !isInitial && hasEctsBudget,
        'border-transparent': isInitial || !hasEctsBudget,
      }"
      :style="{ width: widthEctsBudget + '%' }"
      data-test="study-progress__progress-ects-budget"
    >
      <span
        class="absolute -bottom-px text-xs leading-3 font-normal text-gray-700 align-text-bottom whitespace-nowrap"
        :class="{
          'right-0 text-right pr-2': widthEctsBudget >= 50,
          'left-full': widthEctsBudget < 50,
          'pl-2': widthEctsBudget < 50 && hasEctsBudget,
        }"
        data-test="study-progress__progress-ects-budget-text"
      >
        <template v-if="hasRemainingEctsBudget">
          Noch
          <span class="font-bold">{{ remainingEctsBudget() }} ECTS-Punkte</span>
          verfügbar
        </template>
        <template v-else> <span class="font-bold">Keine ECTS-Punkte</span> verfügbar </template>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StudyProgressBar',
  props: {
    ectsAchieved: {
      type: Number,
      default: 0,
    },
    ectsBooked: {
      type: Number,
      default: 0,
    },
    ectsBudget: {
      type: Number,
      default: 0,
    },
    ectsTotal: {
      type: Number,
      default: 0,
    },
    ectsRecognized: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    isInitial() {
      return this.ectsTotal <= 0;
    },
    hasFinished() {
      return this.ectsTotal > 0 && this.computedEctsAchieved() === this.ectsTotal;
    },
    hasEctsBudget() {
      return this.computedEctsBudget() > 0;
    },
    hasRemainingEctsBudget() {
      return this.remainingEctsBudget() > 0;
    },

    widthEctsAchieved() {
      const ectsAchieved = this.computedEctsAchieved();
      const total = this.ectsTotal;

      if (ectsAchieved <= 0 || total <= 0) {
        return 0;
      }
      return (100 * ectsAchieved) / total; // %
    },
    widthEctsBooked() {
      const ectsBooked = this.computedEctsBooked();
      const total = this.ectsTotal;

      if (ectsBooked <= 0 || total <= 0) {
        return 0;
      }
      return (100 * ectsBooked) / total; // %
    },
    widthEctsBudget() {
      const ectsBudget = this.computedEctsBudget();
      const total = this.ectsTotal;

      if (ectsBudget <= 0 || total <= 0) {
        return 0;
      }
      return (100 * ectsBudget) / total; // %
    },
  },
  methods: {
    computedEctsAchieved() {
      if (this.ectsAchieved <= 0) {
        return 0;
      }
      if (this.ectsAchieved > this.ectsTotal) {
        return this.ectsTotal;
      }
      return this.ectsAchieved;
    },
    computedEctsBooked() {
      const ectsBookedAndRecognized = this.ectsBooked + this.ectsRecognized;

      return Math.min(ectsBookedAndRecognized, this.ectsTotal);
    },
    computedEctsBudget() {
      const budget = this.ectsBudget + this.ectsRecognized;
      if (budget > this.ectsTotal) {
        return this.ectsTotal;
      }
      return budget;
    },
    remainingEctsBudget() {
      return this.computedEctsBudget() - this.computedEctsBooked();
    },
  },
};
</script>

<style scoped>
.bg-stripes {
  background: repeating-linear-gradient(
    -45deg,
    rgb(45, 54, 85),
    rgb(45, 54, 85) 3px,
    rgb(192, 201, 232) 3px,
    rgb(192, 201, 232) 6px
  );
}
</style>
