export default {
  reset(state) {
    state.selectedStudent = null;
    state.loading = null;
    state.studyProgresses = [];
    state.selectedStudyProgress = null;
    state.curriculumModules = [];
    state.curriculumCourses = [];
  },

  setSelectedStudent(state, selectedStudent) {
    state.selectedStudent = selectedStudent;
  },

  setStudyProgressesLoading(state) {
    state.loading = 'Studienfortschritte werden geladen ...';
  },
  setSelectedStudyProgressLoading(state) {
    state.loading = 'Aktueller Fortschritt wird geladen ...';
  },
  resetLoading(state) {
    state.loading = null;
  },

  saveStudyProgresses(state, studyProgresses) {
    state.studyProgresses = studyProgresses;
  },

  setSelectedStudyProgress(state, studyProgress) {
    state.selectedStudyProgress = studyProgress;

    // collect all curriculum modules and courses
    if (studyProgress.studyPlan) {
      state.curriculumModules = [
        ...studyProgress.studyPlan.curriculumTerms.flatMap((term) => term.curriculumModules),
        ...studyProgress.studyPlan.curriculumTerms
          .flatMap((term) => term.curriculumStructureElements)
          .flatMap((structureElement) => structureElement.curriculumModules),
      ];
      state.curriculumCourses = state.curriculumModules.flatMap(
        (module) => module.curriculumCourses
      );
    }
  },

  increaseEctsRecognized(state, ectsToBeAdded) {
    if (Number.isNaN(ectsToBeAdded) || ectsToBeAdded < 0) {
      return;
    }
    state.selectedStudyProgress.ects.recognized += ectsToBeAdded;
    state.selectedStudyProgress.ects.achievedTotal += ectsToBeAdded;
  },
};
