<template>
  <table class="w-full table-fixed">
    <slot />
  </table>
</template>

<style scoped lang="postcss">
table td:first-child,
th:first-child {
  width: 40%;
}

table td:not(:first-child) {
  width: 100%;
}
</style>
