import validator from 'validator';

export const isValidRecognitionLink = function (link) {
  if (link === '') return true;

  return validator.isURL(link, {
    protocols: ['https', 'http'],
    required_tld: true,
    require_protocol: false,
    require_valid_protocol: true,
    host_whitelist: ['careerpartner.lightning.force.com'],
  });
};
