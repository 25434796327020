<template>
  <tr class="bg-blue-50 odd:bg-blue-100 text-center">
    <td class="px-1 first:pl-3 font-bold text-left" data-test="grade-attempt__label">
      <span>{{ examResult.attempt }}. Versuch</span>
    </td>
    <td class="px-1 py-6 font-bold" data-test="grade-attempt__date">
      {{ examResultDateStr }}
    </td>
    <td class="px-1" data-test="grade-attempt__status">
      <span class="flex justify-center">
        <course-status :status="examResult.status" />
      </span>
    </td>
    <td class="px-1" data-test="grade-attempt__score">
      <course-grade
        v-if="!isCancelled && examResult.grade"
        classes="font-bold"
        :status="examResult.status"
        :is-graded="examResult.grade.isGraded"
        :grade="examResult.grade.grade"
        :score="examResult.score"
        :score-max="examResult.maxPossibleScore"
      />
    </td>
    <td class="px-1" data-cy="grades-detail-comment">
      {{ examResult.legacyComment }}
    </td>
    <td>
      <span class="flex justify-center">
        <transferred-icon v-if="examResult.transferred" />
      </span>
    </td>
    <!-- ToDo: remove 'w-64' and v-if when buttons get functionality -->
    <td v-if="false" class="w-64 px-1 last:pr-3">
      <n-tooltip placement="top" content="Prüfungsergebnis bearbeiten" class="mr-8">
        <n-icon icon="mdi-pencil-outline" class="text-cta" />
      </n-tooltip>
      <n-tooltip placement="top" content="Verlauf">
        <n-icon icon="mdi-history" class="text-cta" />
      </n-tooltip>
    </td>
  </tr>
</template>

<script>
import { NTooltip, NIcon } from '@careerpartner/nitro';
import CourseGrade from '@/components/common/grade/CourseGrade.vue';
import { EXAM_RESULT_STATUS } from '@/components/common/grade/exam-result-status';
import CourseStatus from '@/components/common/course-status/CourseStatus.vue';
import TransferredIcon from '../common/TransferredIcon.vue';

export default {
  name: 'GradesTableDetail',
  components: { CourseStatus, CourseGrade, NTooltip, NIcon, TransferredIcon },
  props: {
    examResult: {
      type: Object,
      required: true,
    },
  },
  computed: {
    isCancelled() {
      return this.examResult.status === EXAM_RESULT_STATUS.CANCELLED;
    },
    examResultDateStr() {
      const examResultDate = new Date(this.examResult.date);
      const nullDate = new Date('1970-01-01');

      return examResultDate.valueOf() !== nullDate.valueOf()
        ? examResultDate.toLocaleDateString('de-DE', {})
        : '';
    },
  },
};
</script>
