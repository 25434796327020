import { STUDY_PROGRESS_STATUS } from '@/components/common/study-progress/studyProgressStates';
import { EXAM_RESULT_STATUS } from '@/components/common/grade/exam-result-status';

const ACTIVE_STUDY_PROGRESS_STATES = [
  STUDY_PROGRESS_STATUS.ACTIVE,
  STUDY_PROGRESS_STATUS.ACTIVE_WITH_RESTRICTION,
];

export default {
  hasStudyProgresses: (state) => Boolean(state.studyProgresses?.length),

  getAllStudyProgressesWithoutSelected: (state) => {
    if (state.studyProgresses && state.selectedStudyProgress) {
      return state.studyProgresses.filter(
        (studyProgress) => studyProgress.id !== state.selectedStudyProgress.id
      );
    }

    return state.studyProgresses ? state.studyProgresses : [];
  },

  getFirstStudyProgress: (state) => {
    const firstActiveStudyProgress = state.studyProgresses?.find((item) =>
      ACTIVE_STUDY_PROGRESS_STATES.includes(item.status)
    );

    if (firstActiveStudyProgress) {
      return firstActiveStudyProgress;
    }
    return state.studyProgresses ? state.studyProgresses[0] : undefined;
  },

  getSelectedStudyProgress: (state) => state.selectedStudyProgress,

  doesStudyProgressAllowEnrolment: (state) =>
    ACTIVE_STUDY_PROGRESS_STATES.includes(state.selectedStudyProgress?.status),

  doesStudyProgressAllowRecognition: (_state, getters) => {
    const studyProgress = getters.getSelectedStudyProgress;

    if (!studyProgress) {
      return false;
    }

    const isAllowedStatus = [
      ...ACTIVE_STUDY_PROGRESS_STATES,
      STUDY_PROGRESS_STATUS.ON_HOLD,
    ].includes(studyProgress.status);

    return isAllowedStatus;
  },

  getCurriculumVersionCode: (state) => {
    const programCode = state.selectedStudyProgress?.program?.programCode ?? '';
    const curriculumVersionCode =
      state.selectedStudyProgress?.curriculumVersion?.curriculumVersionCode ?? '';
    return `${programCode} ${curriculumVersionCode}`.trim();
  },

  getAverageGrade: (state) => {
    const avgGrade = state.selectedStudyProgress?.averageGrade;

    if (avgGrade === null || isNaN(avgGrade)) {
      return 'N/A';
    }

    const rounded = Math.round((avgGrade + Number.EPSILON) * 100) / 100;

    return rounded.toLocaleString('de-DE', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  },

  /* curriculum getters */

  studyPlanLoaded: (state) => {
    return !!state.selectedStudyProgress?.studyPlan;
  },

  /**
   * Returns the curriculum terms of the selected study progress.
   *
   * NOTE:
   *  - Removes the term with the value 0 which is a virtual element
   * for additional assignments.
   *  - Adds curriculum structure elements of curriculum term 0 as
   * additional curriculum terms to maintain the existing visual structure.
   *  - Filters by search term
   *    - Filters curriculum terms by title including all child elements for a match
   *    - Filters curriculum structure elements by title including all child elements for a match
   *    - Filters curriculum modules by title and code including all child elements for a match
   *    - Filters curriculum courses by title and code
   *
   * @param {*} state
   * @returns
   */
  getCurriculumTerms: (state, getters, rootState) => {
    let terms = state.selectedStudyProgress?.studyPlan?.curriculumTerms ?? [];
    const term0 = terms.find((ct) => ct.term === 0);
    terms = terms.filter((ct) => ct.term !== 0);

    if (term0) {
      term0.curriculumStructureElements.forEach((cse) => {
        terms.push({
          id: cse.id,
          identifier: cse.title,
          ects: cse.maxEcts,
          curriculumStructureElements: [], // add to not break below filtering
          curriculumModules: cse.curriculumModules,
        });
      });
    }

    // filter by search term if exists
    let filteredTerms = terms;
    if (rootState.overview.searchTerm) {
      const searchTerm = rootState.overview.searchTerm.toLowerCase().trim();

      const filterCurriculumCourses = (cc) =>
        cc.courseTitle?.toLowerCase().includes(searchTerm) ||
        cc.curriculumCourseCode?.toLowerCase().includes(searchTerm);

      const mapCurriculumModules = (cm) => {
        const includeModule =
          cm.moduleTitle?.toLowerCase().includes(searchTerm) ||
          cm.curriculumModuleCode?.toLowerCase().includes(searchTerm);

        const foundCourses = includeModule
          ? cm.curriculumCourses
          : cm.curriculumCourses.filter(filterCurriculumCourses);

        return {
          includeModule: includeModule || foundCourses.length > 0,
          ...cm,
          curriculumCourses: foundCourses,
        };
      };

      const mapCurriculumStructureElements = (cse) => {
        const includeCse = cse.title?.toLowerCase().includes(searchTerm);

        const foundModules = includeCse
          ? cse.curriculumModules
          : cse.curriculumModules.map(mapCurriculumModules).filter((cm) => cm.includeModule);

        return {
          includeCse: includeCse || foundModules.length > 0,
          ...cse,
          curriculumModules: foundModules,
        };
      };

      const mapCurriculumTerms = (ct) => {
        const includeCt = ct.identifier?.toLowerCase().includes(searchTerm);

        const foundChildCse = includeCt
          ? ct.curriculumStructureElements
          : ct.curriculumStructureElements
              .map(mapCurriculumStructureElements)
              .filter((cse) => cse.includeCse);
        const foundChildModules = includeCt
          ? ct.curriculumModules
          : ct.curriculumModules.map(mapCurriculumModules).filter((cm) => cm.includeModule);

        return {
          includeCt: includeCt || foundChildCse.length > 0 || foundChildModules.length > 0,
          ...ct,
          curriculumStructureElements: foundChildCse,
          curriculumModules: foundChildModules,
        };
      };

      filteredTerms = terms.map(mapCurriculumTerms).filter((ct) => ct.includeCt);
    }

    return filteredTerms;
  },

  findCurriculumModuleById: (state) => (id) =>
    state.curriculumModules.find((curriculumModule) => curriculumModule.id === id),

  findManyCurriculumModuleByIds: (state) => (ids) =>
    state.curriculumModules.filter((curriculumModule) => ids.includes(curriculumModule.id)),

  findCurriculumCourseById: (state) => (id) =>
    state.curriculumCourses.find((curriculumCourse) => curriculumCourse.id === id),

  findManyCurriculumCoursesByIds: (state) => (ids) =>
    state.curriculumCourses.filter((curriculumCourse) => ids.includes(curriculumCourse.id)),

  curriculumCourseVersion: () => (curriculumCourse) => {
    const startTerm = curriculumCourse?.startTerm ?? '';
    const startYear = curriculumCourse?.startYear ?? '';
    return `${startTerm} ${startYear}`.trim();
  },

  getLatestExamResult: () => (curriculumCourse) => {
    const examResults = curriculumCourse?.courseProgress?.examResults ?? [];
    for (let i = examResults.length - 1; i >= 0; i--) {
      if (examResults[i].status !== EXAM_RESULT_STATUS.CANCELLED) {
        return examResults[i];
      }
    }
  },

  getTryCount: (state, getters) => (curriculumCourse) => {
    return getters.getLatestExamResult(curriculumCourse)?.attempt;
  },
};
