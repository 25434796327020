<!-- eslint-disable vue/multi-word-component-names -->
<template>
  <n-snackbar :model-value="!!snackbar.message" @update:model-value="setSnackbarMessage('')">
    <template #message>
      <span>{{ snackbar.message }}</span>
    </template>
  </n-snackbar>
</template>

<script>
import { NSnackbar } from '@careerpartner/nitro';
import { mapGetters, mapMutations } from 'vuex';
export default {
  components: {
    NSnackbar,
  },
  computed: {
    ...mapGetters({
      snackbar: 'app/snackbar',
    }),
  },
  methods: {
    ...mapMutations({
      setSnackbarMessage: 'app/setSnackbarMessage',
    }),
  },
};
</script>
