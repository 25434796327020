<template>
  <n-toolbar v-if="!isRecognitionModeActive">
    <n-toolbar-button
      class="uppercase"
      icon="thumb-up-outline"
      :disabled="!doesStudyProgressAllowRecognition"
      text="Anerkennung hinzufügen"
      @click="setRecognitionMode(true)"
    ></n-toolbar-button>
    <n-toolbar-button
      v-if="hasCreateTransferPermission"
      data-test="transfer-button"
      class="uppercase"
      icon="transfer-right"
      text="Leistungen Transferieren"
      @click="navigateToTransfer()"
    ></n-toolbar-button>
  </n-toolbar>
</template>
<script>
import { NToolbar, NToolbarButton } from '@careerpartner/nitro';
import { mapGetters, mapMutations, mapState } from 'vuex';
import { TRANSFER } from '@/common/constants/router/index';
import { IamUser } from '@/common/iam/iamUser';

const CREATE_TRANSFER_PERMISSION = {
  context: 'study-progress',
  resourcePath: 'transfer',
  action: 'c',
};

export default {
  name: 'GradesToolbar',
  components: {
    NToolbar,
    NToolbarButton,
  },
  computed: {
    ...mapGetters({
      doesStudyProgressAllowRecognition: 'studyProgresses/doesStudyProgressAllowRecognition',
    }),
    ...mapState('overview', ['isRecognitionModeActive']),
    hasCreateTransferPermission() {
      return IamUser.hasPermission(
        CREATE_TRANSFER_PERMISSION.resourcePath,
        CREATE_TRANSFER_PERMISSION.action
      );
    },
  },
  methods: {
    ...mapMutations({
      setRecognitionMode: 'overview/setRecognitionMode',
    }),
    async navigateToTransfer() {
      await this.$router.push({
        name: TRANSFER,
      });
    },
  },
};
</script>
