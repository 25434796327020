<template>
  <n-modal
    :is-open="true"
    auto-height
    title="Ausbuchen"
    description="Modal zum Ausbuchen von Modul und Kursen"
    @close="close"
  >
    <div class="text-center">
      <div class="flex justify-center mb-6">
        <div
          class="w-16 h-16 bg-contain bg-no-repeat bg-center"
          :style="`background-image: url('${deleteIcon}');width:56px;height:70px;`"
        ></div>
      </div>
      <h2 class="text-xl font-semibold mb-4">
        Bist Du sicher, dass Du die Einbuchung löschen möchtest?
      </h2>
      <div class="flex justify-center mb-2 gap-5 items-center">
        <span class="font-semibold text-sm">{{ getEnrolmentName(moduleEnrolment) }}</span>
        <module-chip />
      </div>
      <p class="text-gray-700 mb-6">
        Mit der Ausbuchung aus dem Modul werden ebenfalls die zugehörigen Fächer ausgebucht.
      </p>
      <div class="flex justify-center mb-5">
        <n-checkbox
          id="unenrolmentConfirmation"
          v-model="confirmed"
          label="Bestätige Ausbuchung aus folgenden Fächern:"
          check-value="true"
        ></n-checkbox>
      </div>
    </div>

    <div :class="failedUnenrolments.length ? 'mb-4' : 'mb-12'">
      <ul
        class="flex flex-col justify-start text-left bg-blue-100 p-4 gap-y-7 border-t border-gray-500"
      >
        <li v-for="enrolment in courseEnrolments" :key="enrolment.id" class="flex justify-between">
          <span>
            {{ getEnrolmentName(enrolment, true) }}
          </span>
          <course-chip />
        </li>
      </ul>
    </div>

    <n-banner
      v-if="failedUnenrolments.length"
      data-test="enrolment-modal__error-banner"
      class="h-full items-start mb-4"
      variant="error"
    >
      <template #header>
        <div v-if="failedUnenrolments.length === 1">
          {{ failedUnenrolments[0].message }}
        </div>
        <div v-else>
          <span>Ausbuchung aus folgenden Angeboten ist fehlgeschlagen:</span>
        </div>
      </template>
      <template #message>
        <div v-if="failedUnenrolments.length === 1">
          {{ failedUnenrolments[0].description }}
        </div>
        <div v-else>
          <ul class="list-disc list-inside mt-1">
            <li v-for="unenrolment in failedUnenrolments" :key="unenrolment.id">
              {{ unenrolment.message }} {{ unenrolment.description }}
            </li>
          </ul>
        </div>
      </template>
      <template #actions
        ><n-button variant="quaternary" @click="failedUnenrolments = []"
          ><span class="text-white hover:text-gray-100 uppercase"
            ><n-icon icon="mdi-close"></n-icon></span
        ></n-button>
      </template>
    </n-banner>
    <template #actions>
      <n-button :disabled="loading" variant="secondary" @click="close">Abbrechen</n-button>
      <n-button
        :disabled="!confirmed || loading"
        variant="primary"
        class="danger"
        @click="deleteEnrolments"
        >{{ loading ? 'Wird gelöscht...' : 'Löschen' }}</n-button
      >
    </template>
  </n-modal>
</template>

<script>
import { NBanner, NButton, NCheckbox, NIcon, NModal } from '@careerpartner/nitro';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import groupBy from 'lodash.groupby';
import CourseChip from '@/components/common/CourseChip.vue';
import ModuleChip from '@/components/common/ModuleChip.vue';

import { extractErrorDescription, mapErrorMessage } from '@/common/errors';
import { ASSET_URLS } from '@/assets/asset-urls';
import { COURSE_STATUS } from '@/components/common/course-status/course-status';

export default {
  name: 'MultiUnenrolment',
  components: {
    CourseChip,
    ModuleChip,
    NCheckbox,
    NButton,
    NBanner,
    NIcon,
    NModal,
  },

  props: {
    curriculumModule: {
      required: true,
      type: Object,
    },
  },

  data() {
    return {
      courseOffers: [],
      moduleOffers: [],
      confirmed: false,
      loading: false,
      deleteIcon: ASSET_URLS.trashcan,
    };
  },

  computed: {
    ...mapGetters({
      getSelectedStudyProgress: `studyProgresses/getSelectedStudyProgress`,
      getFailedUnenrolments: 'overview/getFailedUnenrolments',
    }),

    failedUnenrolments: {
      get() {
        return this.getFailedUnenrolments;
      },
      set(value) {
        this.setFailedUnenrolments(value);
      },
    },

    moduleEnrolment() {
      const moduleEnrolment = this.curriculumModule.moduleProgress?.moduleEnrolment;
      return {
        ...moduleEnrolment,
        name: this.moduleOffers?.find((offer) => offer.id === moduleEnrolment.moduleOfferId)?.name,
      };
    },

    courseEnrolments() {
      return this.curriculumModule.curriculumCourses
        .filter((curriculumCourse) =>
          [COURSE_STATUS.ENROLED, COURSE_STATUS.EXAM_ADMISSION].includes(
            curriculumCourse.courseProgress?.status
          )
        )
        .map((curriculumCourse) => {
          const courseEnrolment = curriculumCourse.courseProgress?.courseEnrolment;
          const courseOffer = this.courseOffers?.find(
            (offer) => offer.id === courseEnrolment?.courseOfferId
          );

          return {
            ...courseEnrolment,
            name: courseOffer?.name,
            ects: curriculumCourse?.ects,
          };
        });
    },
  },

  async mounted() {
    const [moduleOfferResult, ...courseOfferResults] = await Promise.allSettled([
      this.fetchModuleOffers({
        curriculumModuleId: this.curriculumModule.id,
        moduleMainVersionId: this.curriculumModule.moduleMainVersionId,
      }),
      ...this.curriculumModule.curriculumCourses.map((curriculumCourse) =>
        this.fetchCourseOffers({
          curriculumCourseId: curriculumCourse.id,
          courseMainVersionId: curriculumCourse.courseMainVersionId,
        })
      ),
    ]);
    if (moduleOfferResult.status === 'fulfilled') {
      this.moduleOffers = moduleOfferResult.value;
    }
    this.courseOffers = courseOfferResults
      .filter((result) => result.status === 'fulfilled')
      .flatMap((result) => result.value);
  },

  methods: {
    ...mapMutations({
      setSnackbarMessage: 'app/setSnackbarMessage',
      resetOffers: 'offers/reset',
      setFailedUnenrolments: 'overview/setFailedUnenrolments',
      closeAllModals: 'overview/closeAllModals',
    }),

    ...mapActions({
      deleteModuleEnrolment: 'enrolments/deleteModuleEnrolment',
      deleteCourseEnrolment: 'enrolments/deleteCourseEnrolment',
      fetchModuleOffers: 'offers/fetchModuleOffers',
      fetchCourseOffers: 'offers/fetchCourseOffers',
      fetchDataForSelectedStudyProgress: 'studyProgresses/fetchDataForSelectedStudyProgress',
    }),

    close() {
      this.resetOffers();
      this.closeAllModals();
    },

    getEnrolmentName(enrolment, isCourse = false) {
      if (enrolment?.name) return enrolment.name;
      if (isCourse) return 'Vorlesungsreihe Unbekannt';
      else return 'Modulangebot Unbekannt';
    },

    async deleteEnrolments() {
      this.failedUnenrolments = [];
      this.loading = true;

      const results = await Promise.allSettled(
        this.courseEnrolments.map((courseEnrolment) =>
          this.deleteCourseEnrolment(courseEnrolment.id)
        )
      );

      const resultsWithEnrolment = results.flatMap((result, index) => ({
        ...result,
        enrolment: this.courseEnrolments[index],
      }));

      const { rejected, fulfilled } = groupBy(resultsWithEnrolment, 'status');

      console.log('rejected deletion is', rejected);

      const errors =
        rejected
          ?.filter((result) => {
            if (result.reason.response && result.reason.response.status === 404) {
              return false;
            }

            return true;
          })
          .map((result) => ({
            ...result.enrolment,
            message: mapErrorMessage(result.reason, this.getEnrolmentName(result.enrolment)),
            description: extractErrorDescription(result.reason),
          })) ?? [];

      const successes = fulfilled?.map((result) => result.enrolment) ?? [];

      if (!errors.length) {
        try {
          await this.deleteModuleEnrolment(this.moduleEnrolment.id);
          successes.push(this.moduleEnrolment);
        } catch (error) {
          if (error.response && error.response.status === 404) {
            successes.push(this.moduleEnrolment);
          } else {
            errors.push({
              ...this.moduleEnrolment,
              message: mapErrorMessage(error, 'Buchung'),
            });
          }
        }
      }

      const successCount = successes.length;

      if (successCount > 0) {
        await this.fetchDataForSelectedStudyProgress(this.getSelectedStudyProgress.id);
        this.setSnackbarMessage(
          `${successCount} ${
            successCount > 1 ? 'Einbuchungen wurden' : 'Einbuchung wurde'
          } erfolgreich gelöscht.`
        );
      }

      if (!errors.length) {
        this.close();
      }

      this.failedUnenrolments = errors;
      this.loading = false;
    },
  },
};
</script>
