import { StudyProgressAPI } from '@/services/study-progress-api';

const fetchOwnershipForStudyProgress = async (studyProgress) => {
  try {
    const { data } = await StudyProgressAPI.getOwnershipForStudyProgress(studyProgress.id);
    studyProgress.ownership = {
      isOwnedByEPOS: data.ownership === 'epos',
      error: null,
    };
  } catch (error) {
    if (error.response.status === 404) {
      studyProgress.ownership = {
        isOwnedByEPOS: false,
        error: null,
      };
    } else {
      studyProgress.ownership = {
        isOwnedByEPOS: false,
        error: {
          httpStatus: error.response.status,
          body: error.response.data,
        },
      };
    }
  }
  return studyProgress;
};

export default {
  async fetchStudyProgresses({ commit, dispatch }, careUserId) {
    try {
      commit('setStudyProgressesLoading');
      const { data } = await StudyProgressAPI.getStudyProgressesForUser(careUserId);
      let studyProgresses = data.data;

      studyProgresses = await Promise.all(
        studyProgresses.map((studyProgress) => fetchOwnershipForStudyProgress(studyProgress))
      );

      commit('saveStudyProgresses', studyProgresses);
      return studyProgresses;
    } catch (error) {
      console.error(error);
      dispatch('app/setError', error, { root: true });
    } finally {
      commit('resetLoading');
    }
  },

  async fetchDataForSelectedStudyProgress({ commit, dispatch, state }) {
    const selectedStudyProgress = state.selectedStudyProgress;

    if (!selectedStudyProgress) {
      await dispatch(`app/navigateToNotFound`, null, { root: true });
      return;
    }

    try {
      commit('setSelectedStudyProgressLoading');
      const { data } = await StudyProgressAPI.getStudyProgress(selectedStudyProgress.id);
      let studyProgress = data.data;

      studyProgress = await fetchOwnershipForStudyProgress(studyProgress);

      commit('setSelectedStudyProgress', studyProgress);
      return studyProgress;
    } catch (error) {
      console.error(error);
      dispatch('app/setError', error, { root: true });
    } finally {
      commit('resetLoading');
    }
  },

  async setSelectedStudyProgress({ commit, dispatch, state }, studyProgressId) {
    // Return if the selected study progress is already selected
    if (state.selectedStudyProgress?.id === studyProgressId) {
      return;
    }

    const selectedStudyProgress = state.studyProgresses.find(
      (studyProgress) => studyProgress.id === studyProgressId
    );

    if (!selectedStudyProgress) {
      dispatch(
        'app/setError',
        new Error('Für dieses Profil sind keine Studienfortschritte vorhanden!'),
        { root: true }
      );
    } else {
      commit('overview/resetSearchTerm', null, { root: true });
      commit('setSelectedStudyProgress', selectedStudyProgress);
      await dispatch(`fetchDataForSelectedStudyProgress`);
    }
  },
};
