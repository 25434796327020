<template>
  <grades-loader v-if="loading || !curriculumCourse">{{ loading }}</grades-loader>
  <div v-else>
    <div class="my-12 pl-3">
      <span class="font-bold pr-3">{{ curriculumCourse.courseTitle }}</span>
      <course-chip />
      <p class="text-xs text-gray-700">Version: {{ curriculumCourseVersionText }}</p>
      <p class="text-xs text-gray-700">ECTS-Punkte: {{ curriculumCourse.ects }}</p>
    </div>
    <grades-detail :curriculum-course="curriculumCourse" />
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import CourseChip from '@/components/common/CourseChip.vue';
import GradesDetail from '@/components/detail/GradesDetail.vue';
import pageTitleMixin from '@/common/mixins/page-title';
import GradesLoader from '@/components/common/Loader.vue';

export default {
  name: 'CourseDetailView',
  components: { CourseChip, GradesLoader, GradesDetail },
  mixins: [pageTitleMixin],

  data: () => ({
    studyProgresses: [],
    pageTitle: 'Kursdetail',
  }),

  computed: {
    ...mapState('studyProgresses', ['loading']),
    ...mapGetters({
      profileAcademyId: `app/getAcademyId`,
      hasStudyProgresses: `studyProgresses/hasStudyProgresses`,
      findCurriculumCourseById: `studyProgresses/findCurriculumCourseById`,
      curriculumCourseVersion: `studyProgresses/curriculumCourseVersion`,
    }),
    curriculumCourseVersionText: {
      get() {
        return this.curriculumCourseVersion(this.curriculumCourse);
      },
    },
    curriculumCourse() {
      return this.findCurriculumCourseById(this.$route.params.courseId);
    },
  },

  async beforeMount() {
    if (!this.hasStudyProgresses) {
      this.studyProgresses = await this.fetchStudyProgresses(this.profileAcademyId);

      if (!this.studyProgresses?.length) {
        await this.$store.dispatch(`app/navigateToNotFound`, null, {
          root: true,
        });
        return;
      }
    }

    await this.setSelectedStudyProgress(this.$route.params.bookingId);
  },

  methods: {
    ...mapActions({
      fetchStudyProgresses: `studyProgresses/fetchStudyProgresses`,
      setSelectedStudyProgress: `studyProgresses/setSelectedStudyProgress`,
    }),
  },
};
</script>
