<template>
  <multi-unenrolment
    v-if="hasMultipleEnrolments"
    :curriculum-module="curriculumModule"
  ></multi-unenrolment>
  <single-unenrolment
    v-else
    :curriculum-module="curriculumModule"
    :curriculum-course="curriculumCourse"
  ></single-unenrolment>
</template>

<script>
import { mapGetters } from 'vuex';
import MultiUnenrolment from './MultiUnenrolment.vue';
import SingleUnenrolment from './SingleUnenrolment.vue';

export default {
  name: 'UnenrolmentModal',
  components: {
    MultiUnenrolment,
    SingleUnenrolment,
  },

  props: {
    curriculumModule: {
      type: Object,
      required: true,
    },
    curriculumCourse: {
      type: Object,
      default: null,
    },
  },

  computed: {
    ...mapGetters({
      isCurriculumModuleUnenrolable: 'enrolments/isCurriculumModuleUnenrolable',
      isCurriculumCourseUnenrolable: 'enrolments/isCurriculumCourseUnenrolable',
    }),

    id() {
      return this.curriculumCourse ? this.curriculumCourse?.id : this.curriculumModule?.id;
    },

    hasMultipleEnrolments() {
      return (
        !this.curriculumCourse &&
        this.curriculumModule &&
        this.curriculumModule.curriculumCourses?.some((curriculumCourse) =>
          this.isCurriculumCourseUnenrolable(curriculumCourse)
        )
      );
    },
  },
};
</script>

<style lang="css" scoped>
.single-unenrolment-modal .modal-container {
  width: 350px !important;
}
</style>
