import { StudyProgressAPI } from '@/services/study-progress-api';

export default {
  async createCourseEnrolment(_helpers, { studyProgressId, courseOfferId, curriculumCourseId }) {
    return StudyProgressAPI.createCourseEnrolment({
      studyProgressId,
      courseOfferId,
      curriculumCourseId,
    });
  },

  async createModuleEnrolment(_helpers, { studyProgressId, moduleOfferId, curriculumModuleId }) {
    return StudyProgressAPI.createModuleEnrolment({
      studyProgressId,
      moduleOfferId,
      curriculumModuleId,
    });
  },

  async deleteModuleEnrolment(_helpers, moduleEnrolmentId) {
    return StudyProgressAPI.deleteModuleEnrolment(moduleEnrolmentId);
  },

  async deleteCourseEnrolment(_helpers, courseEnrolmentId) {
    return StudyProgressAPI.deleteCourseEnrolment(courseEnrolmentId);
  },
};
